import React from "react";
import { Button, Flex, TableContainer, useDisclosure } from "@chakra-ui/react";
import AddContainersToHouseholds from "./AddContainersToHouseholds";
import { useGetHouseholdsQuery } from "../../features/api/ApiSlice";
import ContainerTable from "../Containers/ContainerTable";
import { AddIcon } from "@chakra-ui/icons";
import ExportToExcelContainers from "../Containers/ExportToExcelContainers";
import { HouseholdType } from "../../validators/Household";

interface HouseholdContainersProps {
  householdData: HouseholdType;
  isHouseholdLoading: boolean;
  isHouseholdSuccess: boolean;
  locationId: string;
}

const HouseholdContainers = ({
  householdData,
  isHouseholdLoading,
  isHouseholdSuccess,
  locationId,
}: HouseholdContainersProps) => {
  // const {
  //   isLoading: isHouseholdLoading,
  //   data: household,
  //   isSuccess: isHouseholdSuccess,
  // } = useGetHouseholdsQuery(householdId);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Flex mt="2rem" width="27rem" alignItems={"center"}>
        <ExportToExcelContainers
          tableData={householdData ? householdData.ContainerData ?? [] : []}
          fileName={`household_containers`}
        />
        <Button
          size={"xs"}
          colorScheme="teal"
          variant={"outline"}
          onClick={onOpen}
          rightIcon={<AddIcon />}
          ml={2}
        >
          Gi tilgang til containere
        </Button>
        {isOpen && householdData.Id ? (
          <AddContainersToHouseholds
            onClose={onClose}
            isOpen={isOpen}
            householdId={householdData.Id}
            locationId={locationId}
            householdLocations={
              householdData ? householdData.ContainerData ?? [] : []
            }
          />
        ) : null}
      </Flex>
      <TableContainer mt={"3rem"}>
        <ContainerTable
          containerList={householdData ? householdData.ContainerData ?? [] : []}
          isLoading={isHouseholdLoading}
          isSuccess={isHouseholdSuccess}
          noContainersMessage="Det finnes ingen containere på denne lokasjonen"
        />
      </TableContainer>
    </>
  );
};

export default HouseholdContainers;
