import { z } from "zod";

const ScheduleDay = z.object({
  Default: z.string().nullable(),
  End: z.string().nullable(),
  Mode: z.string().nullable(),
  Start: z.string().nullable(),
});

const Schedule = z.object({
  Created: z.number().nullable(),
  Id: z.string(),
  Monday: ScheduleDay,
  Tuesday: ScheduleDay,
  Wednesday: ScheduleDay,
  Thursday: ScheduleDay,
  Friday: ScheduleDay,
  Saturday: ScheduleDay,
  Sunday: ScheduleDay,
});

const NewSchedule = z.object({
  Monday: ScheduleDay,
  Tuesday: ScheduleDay,
  Wednesday: ScheduleDay,
  Thursday: ScheduleDay,
  Friday: ScheduleDay,
  Saturday: ScheduleDay,
  Sunday: ScheduleDay,
});

const Schedules = z.array(Schedule);

export { Schedule, Schedules, NewSchedule };
export type ScheduleType = z.infer<typeof Schedule>;
export type SchedulesType = z.infer<typeof Schedules>;
export type ScheduleDayType = z.infer<typeof ScheduleDay>;
export type NewScheduleType = z.infer<typeof NewSchedule>;
