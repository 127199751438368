import React from "react";
import { useParams } from "react-router-dom";
import { colors } from "../../styles/variables";
import {
  Badge,
  Text,
  Spinner,
  TabList,
  Tab,
  Tabs,
  TabPanels,
  TabPanel,
  Center,
  Flex,
  Box,
} from "@chakra-ui/react";
import UsersHousehold from "../Users/UsersHousehold";
import TagsHousehold from "../Tags/TagsHousehold";
import HouseholdContainers from "./HouseholdContainers";
import { useGetHouseholdsQuery } from "../../features/api/ApiSlice";

interface RouteParams {
  id: string;
}

const HouseholdDetails = () => {
  const { id } = useParams<RouteParams>();
  const {
    data: householdData,
    isLoading: isHouseholdLoading,
    isSuccess: isHouseholdSuccess,
  } = useGetHouseholdsQuery(id);

  const makeOneString = (obj: any) => {
    let address;
    address = `${obj?.StreetName} ${obj?.StreetNumber} ${
      obj?.Letter ? obj?.Letter : ""
    }, ${obj?.PostalCode} ${obj?.City}`;
    return address;
  };

  return (
    <Flex
      h={"92.5vh"}
      flex={1}
      overflow={"auto"}
      bgColor={`${colors.greyLight}`}
    >
      <Box
        fontSize={"1.25rem"}
        p={"2rem"}
        m={"2rem"}
        borderRadius={"5px"}
        bg={"white"}
        height={"auto"}
        overflow={"auto"}
      >
        <Text as="h1" fontWeight={"600"}>
          Boenhetsdetaljer
        </Text>
        <hr style={{ borderTop: "1px solid lightgrey" }} />
        <Tabs mt={"3rem"} variant="soft-rounded" size="sm" colorScheme="green">
          <TabList>
            <Tab>Info</Tab>
            <Tab>Containere</Tab>
            <Tab>App-brukere</Tab>
            <Tab>Brikker</Tab>
          </TabList>
          <TabPanels>
            <TabPanel style={{ padding: "0px" }}>
              <Flex
                mt={"2rem"}
                width={"100%"}
                mr={"2rem"}
                fontSize={"1.05rem"}
                alignItems={"center"}
              >
                <Text style={{ marginRight: "1rem" }} as="b">
                  Adresse:{" "}
                </Text>
                {householdData ? (
                  makeOneString(householdData[0])
                ) : isHouseholdLoading ? (
                  <Center>
                    <Spinner size="xs" color={colors.greenMain} />
                  </Center>
                ) : (
                  <Badge>N/A</Badge>
                )}
              </Flex>
            </TabPanel>
            <TabPanel>
              {householdData && householdData[0].Units ? (
                <HouseholdContainers
                  householdData={householdData[0]}
                  locationId={householdData[0].Units}
                  isHouseholdLoading={isHouseholdLoading}
                  isHouseholdSuccess={isHouseholdSuccess}
                />
              ) : (
                <Center>
                  <Spinner colorScheme="teal" />
                </Center>
              )}
            </TabPanel>
            <TabPanel>
              <UsersHousehold householdId={id} />
            </TabPanel>
            <TabPanel>
              {householdData ? (
                <TagsHousehold
                  tagsHousehold={householdData[0].Tags}
                  householdId={id}
                />
              ) : (
                <Center>
                  <Spinner colorScheme="teal" />
                </Center>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Flex>
  );
};

export default HouseholdDetails;
