import React, { useState, useRef } from "react";
import styled from "styled-components";
import { colors } from "../../styles/variables";
import {
  Td,
  Badge,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Select,
  Box,
} from "@chakra-ui/react";
import axios from "axios";
import { ReactComponent as Edit } from "../../assets/icons/Edit.svg";
import { useGetNodesQuery } from "../../features/api/ApiSlice";

const ContainerTableElementDropdown = ({
  tableValue,
  editValue,
  containerId,
  getContainers,
}) => {
  const editModal = useDisclosure();
  const [edit, setEdit] = useState(false);
  const tableValueSelectInputRef = useRef();
  const token = localStorage.getItem("API_token");

  const { data } = useGetNodesQuery();
  let headers = {
    authorization: `Bearer ${token}`,
  };

  const openEditBoxHandler = (e) => {
    setEdit(!edit);
  };
  const openEditModalHandler = () => {
    editModal.onOpen();
  };

  const changeTextHandler = (val) => {
    let editedField;
    if (val.match(/\d/)) {
      editedField = {
        [val]: Number(tableValueSelectInputRef.current.value),
      };
    } else {
      editedField = {
        [val]: tableValueSelectInputRef.current.value,
      };
      console.log(editedField);
    }
    axios
      .put(
        `https://gpshu4lon5.execute-api.eu-north-1.amazonaws.com/Test/containers/${containerId}`,
        editedField,
        { headers: headers }
      )
      .then((res) => {
        if (typeof tableValueSelectInputRef.current.value === "string") {
          tableValueSelectInputRef.current.value = "";
        }
        editModal.onClose();
        getContainers();
      })
      .catch((err) => console.log(err));
  };

  const optionNodesList = data?.map((node, id) => (
    <option key={id} value={node.ThingName}>
      {node.ThingName}
    </option>
  ));
  return (
    <>
      <TableData
        onMouseEnter={openEditBoxHandler}
        onMouseLeave={openEditBoxHandler}
      >
        <Box onClick={openEditModalHandler}>
          {tableValue || <Badge>N/A</Badge>}
          {edit && <IconEdit />}
        </Box>
      </TableData>

      <Modal
        motionPreset="slideInBottom"
        isCentered
        isOpen={editModal.isOpen}
        onClose={editModal.onClose}
      >
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton size="sm" />
          <ModalHeader>Rediger Node</ModalHeader>
          <ModalBody>
            <Select
              ref={tableValueSelectInputRef}
              placeholder="velg en node"
              size="md"
            >
              {optionNodesList}
            </Select>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="teal"
              variant="solid"
              mr={3}
              onClick={() => changeTextHandler(editValue)}
            >
              Lagre
            </Button>
            <Button
              variant="ghost"
              colorScheme="teal"
              mr={3}
              onClick={() => editModal.onClose()}
            >
              Avbryt
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const IconEdit = styled(Edit)`
  width: 0.65rem;
  height: 0.65rem;
  cursor: pointer;
  position: absolute;
  left: -12px;
  top: -5px;
  margin: 1rem;
`;
const TableData = styled(Td)`
  position: relative;
  &:hover {
    background-color: ${colors.greyLight};
  }
`;

export default ContainerTableElementDropdown;
