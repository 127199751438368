export const URL_NAMES = {
    HOME: "/", 
    ADMINS: "/administratorer",
    USERS: "/brukere",
    LOCATIONS: "/lokasjoner",
    HOUSING_UNITS: "/boenheter", 
    LOCK_NODES: "/laaser",
    CONTAINERS: "/containere",
    TAGS: "/brikker",
    PROFILE: "/profil",

} as const;