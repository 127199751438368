import React from "react";
import { Switch, Route } from "react-router-dom";
import { URL_NAMES } from "../types/urlNames";
import Home from "../components/Home";
import Admins from "../components/Admins/Admins";
import Users from "../components/Users/Users";
import Locations from "../components/Locations/Locations.js";
import AllContainers from "../components/Containers/AllContainers.js";
import Nodes from "../components/Nodes/Nodes.js";
import HouseholdsList from "../components/Households/HouseholdsList.js";
import Profile from "../components/Profile.js";
import AdminDetails from "../components/Admins/AdminDetails";
import LocationDetails from "../components/Locations/LocationDetails.js";
import HouseholdDetails from "../components/Households/HouseholdDetails";
import ContainerLog from "../components/Containers/ContainerLog";
import NodeLog from "../components/Nodes/NodeLog";
import Tags from "../components/Tags/Tags";

const PortalRoutes = ({ signOut, user }) => {
  //TODO: Move this?
  const signOutHandler = () => {
    signOut();
    localStorage.clear("API_token");
  };

  const appRoutes = [
    {
      path: `${URL_NAMES.ADMINS}/:id`,
      exact: true,
      component: () => <AdminDetails />,
    },
    {
      path: URL_NAMES.ADMINS,
      component: () => <Admins />,
    },
    {
      path: URL_NAMES.USERS,
      component: () => <Users />,
    },
    {
      path: `${URL_NAMES.LOCATIONS}/:id`,
      exact: true,
      component: () => <LocationDetails />,
    },
    {
      path: URL_NAMES.LOCATIONS,
      component: () => <Locations />,
    },
    {
      path: `${URL_NAMES.HOUSING_UNITS}/:id`,
      exact: true,
      component: () => <HouseholdDetails />,
    },
    {
      path: URL_NAMES.HOUSING_UNITS,
      component: () => <HouseholdsList />,
    },
    {
      path: `${URL_NAMES.CONTAINERS}/:id`,
      exact: true,
      component: () => <ContainerLog />,
    },
    {
      path: URL_NAMES.CONTAINERS,
      component: () => <AllContainers />,
    },
    {
      path: `${URL_NAMES.LOCK_NODES}/:id`,
      exact: true,
      component: () => <NodeLog />,
    },
    {
      path: URL_NAMES.LOCK_NODES,
      component: () => <Nodes />,
    },
    {
      path: URL_NAMES.TAGS,
      component: () => <Tags />,
    },

    {
      path: URL_NAMES.PROFILE,
      component: () => <Profile signOut={signOutHandler} user={user} />,
    },
    {
      path: URL_NAMES.HOME,
      component: () => <Home />,
    },
    //TODO: Check if we need a 404 route here??
  ];
  return (
    <>
      <Switch>
        {appRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            children={<route.component />}
            strict={route.strict}
          />
        ))}
      </Switch>
    </>
  );
};

export default PortalRoutes;
