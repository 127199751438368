import React from "react";
import { colors } from "../../styles/variables";
import { Link } from "react-router-dom";
import {
  Button,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  Flex,
  CloseButton,
} from "@chakra-ui/react";
import { URL_NAMES } from "../../types/urlNames";
import { useDeleteAdminMutation } from "../../features/api/ApiSlice";
import { AdminType } from "../../validators/Admin";

interface AdminProps {
  admin: AdminType;
}

const Admin = ({ admin }: AdminProps): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [deleteAdmin] = useDeleteAdminMutation();

  return (
    <>
      <Flex
        padding={"0.25rem"}
        width={"30rem"}
        borderRadius={"5px"}
        border={`2px solid ${colors.greenDark}`}
        alignItems={"center"}
        justifyContent={"space-between"}
        bgColor={"white"}
        transition={"all 0.2s ease-in-out"}
        _hover={{
          bgColor: `${colors.greenPaleLight}`,
          boxShadow: "1px 4px 8px -4px rgba(79, 79, 79, 0.48)",
          WebkitBoxShadow: "1px 4px 8px -4px rgba(79, 79, 79, 0.48)",
        }}
      >
        <Link style={{ flex: "1" }} to={`${URL_NAMES.ADMINS}/${admin.Id}`}>
          <Text pl={"0.5rem"} fontSize={"0.90rem"} fontWeight={"medium"}>
            {admin.Email}
          </Text>
          {/* TODO: Check what isLogged is doing? */}
          {/* {isLogged ? (
            <Badge colorScheme="green" variant="subtle">
              pålogget
            </Badge>
          ) : null} */}
        </Link>
        <CloseButton title="slett" onClick={onOpen} size={"sm"} />
      </Flex>
      <Modal
        motionPreset="slideInBottom"
        isCentered
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Er du sikker på at du vil slette denne administratoren?
          </ModalHeader>
          <ModalCloseButton />
          <ModalFooter>
            <Button
              colorScheme="teal"
              variant="solid"
              mr={3}
              onClick={() => {
                deleteAdmin(admin.Id);
                onClose();
              }}
            >
              Ja
            </Button>
            <Button variant="ghost" colorScheme="teal" mr={3} onClick={onClose}>
              Nei
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Admin;
