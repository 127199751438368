import {
  Thead,
  Tr,
  Th,
  Tbody,
  Table,
  Text,
  Tag,
  TagLabel,
  Flex,
  Icon,
  IconButton,
  TagLeftIcon,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ReactComponent as Sorting } from "../../assets/icons/Sorting.svg";
import TagRow from "./TagRow";
import { TagWithAddressType } from "../../validators/Tag";
import sortColumn from "../../utilities/sortColumn";
import { InfoOutlineIcon } from "@chakra-ui/icons";

interface TagTableProps {
  tagsList: TagWithAddressType[] | undefined;
  noTagsMessage: string;
}

const TagTable = ({ tagsList, noTagsMessage }: TagTableProps) => {
  const [order, setOrder] = useState("ASC");
  const [tags, setTags] = useState<TagWithAddressType[]>([]);

  useEffect(() => {
    if (tagsList) {
      setTags(tagsList);
    }
  }, [tagsList]);

  const tagTable = (
    <Table size={"sm"} variant="simple" colorScheme={"teal"}>
      <Thead>
        <Tr>
          <Th>
            <Flex alignItems={"center"}>
              <Text fontSize={10}>Intern id</Text>
              <IconButton
                size={"xs"}
                variant={"outline"}
                ml={1}
                icon={<Icon as={Sorting} />}
                aria-label="sort by internal id"
                onClick={() =>
                  sortColumn<TagWithAddressType>(
                    "InternalId",
                    order,
                    setOrder,
                    setTags,
                    tags
                  )
                }
              />
            </Flex>
          </Th>
          <Th>
            <Flex alignItems={"center"}>
              <Text fontSize={10}>Ekstern id</Text>
              <IconButton
                size={"xs"}
                variant={"outline"}
                ml={1}
                icon={<Icon as={Sorting} />}
                aria-label="sort by external id"
                onClick={() =>
                  sortColumn<TagWithAddressType>(
                    "ExternalId",
                    order,
                    setOrder,
                    setTags,
                    tags
                  )
                }
              />
            </Flex>
          </Th>
          <Th>
            <Flex alignItems={"center"}>
              <Text fontSize={10}>Addresse</Text>
              <IconButton
                size={"xs"}
                variant={"outline"}
                ml={1}
                icon={<Icon as={Sorting} />}
                aria-label="sort by address"
                onClick={() =>
                  sortColumn<TagWithAddressType>(
                    "StreetName",
                    order,
                    setOrder,
                    setTags,
                    tags
                  )
                }
              />
            </Flex>
          </Th>
          <Th>
            <Flex alignItems={"center"}>
              <Text fontSize={10}>Sted</Text>
              <IconButton
                size={"xs"}
                variant={"outline"}
                ml={1}
                icon={<Icon as={Sorting} />}
                aria-label="sort by place"
                onClick={() =>
                  sortColumn<TagWithAddressType>(
                    "PostalCode",
                    order,
                    setOrder,
                    setTags,
                    tags
                  )
                }
              />
            </Flex>
          </Th>
          <Th>
            <Flex alignItems={"center"}>
              <Text fontSize={10}>Eier</Text>
              <IconButton
                size={"xs"}
                variant={"outline"}
                ml={1}
                icon={<Icon as={Sorting} />}
                aria-label="sort by owner"
                onClick={() =>
                  sortColumn<TagWithAddressType>(
                    "Owner",
                    order,
                    setOrder,
                    setTags,
                    tags
                  )
                }
              />
            </Flex>
          </Th>
          <Th>
            <Flex alignItems={"center"}>
              <Text fontSize={10}>Opprettet</Text>
              <IconButton
                size={"xs"}
                variant={"outline"}
                ml={1}
                icon={<Icon as={Sorting} />}
                aria-label="sort by created"
                onClick={() =>
                  sortColumn<TagWithAddressType>(
                    "Created",
                    order,
                    setOrder,
                    setTags,
                    tags
                  )
                }
              />
            </Flex>
          </Th>
          <Th>
            <Flex alignItems={"center"}>
              <Text fontSize={10}>Slett</Text>
            </Flex>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {tags.map((tag) => (
          <TagRow tag={tag} />
        ))}
      </Tbody>
    </Table>
  );

  const noTags = (
    <Flex pt={"1.5rem"} w={"100%"} h={"4rem"} justifyContent={"center"}>
      <Tag variant="outline" size="md">
        <TagLeftIcon as={InfoOutlineIcon} />
        <TagLabel>{noTagsMessage}</TagLabel>
      </Tag>
    </Flex>
  );

  return tags.length === 0 ? noTags : tagTable;
};
export default TagTable;
