import React, { useState, useRef, useEffect } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { colors, fonts } from "../../styles/variables";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Badge,
  useToast,
  Tag,
  TagLabel,
  TagRightIcon,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Stack,
  StackDivider,
  textDecoration,
  HStack,
  VStack,
  Input,
  Checkbox,
  Text,
  Radio,
  RadioGroup,
  FormControl,
  Spinner,
} from "@chakra-ui/react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { custom_date } from "../../utilities/customDate";
import { ReactComponent as ExportOrange } from "../../assets/icons/ExportOrange.svg";
import { ArrowUpIcon } from "@chakra-ui/icons";

const ExportToExcelContainers = ({ fileName, tableData }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (tableData, fileName) => {
    const copiedTableData = [...tableData];
    const mappedArray = copiedTableData.map((obj) => {
      const newObj = { ...obj };

      Object.keys(newObj).forEach((key) => {
        if (newObj[key] === null) {
          newObj[key] = "N/A";
        }

        delete newObj.ContainerData;
        delete newObj.Containers;
        delete newObj.TagData;
        delete newObj.Email;
        delete newObj.LastFail;
        delete newObj.LastUse;
        delete newObj.Units;
        delete newObj.UnitNames;
        delete newObj.Id;
        delete newObj.StartUp;
        delete newObj.Created;
        delete newObj.Tags;
      });

      return newObj;
    });

    const ws = XLSX.utils.json_to_sheet(mappedArray);
    XLSX.utils.sheet_add_aoa(
      ws,
      [
        [
          "Sted",
          "Postnummer",
          "Gatenavn",
          "Gatenummer",
          "Bokstav",
          "Leiligstesnummer",
          "Avtale-id",
          "Seksjon-id",
          "Innsamlings-id",
          "Business-type",
          "Lokasjon",
        ],
      ],
      {
        origin: "A1",
      }
    );
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return (
    <Button
      size="xs"
      variant={"outline"}
      colorScheme="orange"
      onClick={(e) => exportToCSV(tableData, fileName)}
      rightIcon={<ArrowUpIcon />}
    >
      Eksporter tabellen
    </Button>
  );
};

export default ExportToExcelContainers;
