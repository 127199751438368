import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { colors } from "../../styles/variables";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Tag,
  Spinner,
} from "@chakra-ui/react";
import LocationInfo from "./LocationInfo";
import axios from "axios";
import { useParams } from "react-router-dom";

import HouseholdsLocation from "../Households/HouseholdsLocation";
import ContainersLocation from "./LocationContainers";

const LocationDetails = () => {
  const dispatch = useDispatch();
  // const [containers, setContainers] = useState([]);
  const [location, setLocation] = useState();
  const { id } = useParams();
  const token = localStorage.getItem("API_token");
  let headers = {
    authorization: `Bearer ${token}`,
  };

  const fetchLocation = () => {
    axios
      .get(
        `https://gpshu4lon5.execute-api.eu-north-1.amazonaws.com/Test/units/${id}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        setLocation(res.data);
      });
  };

  useEffect(() => {
    fetchLocation();
  }, [id]);

  const changeLocationStatusHandler = (isActive) => {
    axios
      .put(
        `https://gpshu4lon5.execute-api.eu-north-1.amazonaws.com/Test/units/${id}`,
        { Active: isActive ? true : false },
        { headers: headers }
      )
      .then((res) => {
        res.status === 200 && fetchLocation();
      });
  };

  return (
    <AdminInfoContainer>
      <Content>
        <Title>Lokasjonsdetaljer</Title> <BreakingLine />
        <TabContainer variant="soft-rounded" size="sm" colorScheme="green">
          <TabList>
            <Tab>Info</Tab>
            <Tab>Boenheter</Tab>
            <Tab>Containere</Tab>
          </TabList>
          {location ? (
            <TabPanels>
              <TabPanel>
                <LocationInfo
                  location={location}
                  statusPutApi={changeLocationStatusHandler}
                />
              </TabPanel>
              <TabPanel>
                <HouseholdsLocation locationId={id} />
              </TabPanel>
              <TabPanel>
                <ContainersLocation
                  locationId={id}
                  locationName={location.Name}
                />
              </TabPanel>
            </TabPanels>
          ) : (
            <SpinnerWrapper>
              <Spinner size="lg" color={colors.greenMain} />
            </SpinnerWrapper>
          )}
        </TabContainer>
      </Content>
    </AdminInfoContainer>
  );
};

const AdminInfoContainer = styled.div`
  height: 92.5vh;
  display: flex;
  flex: 1;
  background-color: ${colors.greyLight};
`;
const Content = styled.div`
  font-size: 1.25rem;
  padding: 2.5rem;
  margin: 2rem;
  font-family: "Roboto";
  width: 85%;
  background-color: white;
  height: auto;
  overflow: auto;
  border-radius: 5px;
`;

const Title = styled.h1`
  font-weight: 600;
`;
const BreakingLine = styled.hr`
  border-top: 1px solid lightgrey;
`;

const TabContainer = styled(Tabs)`
  margin-top: 3rem;
`;
const AddContainer = styled(Tag)`
  margin-top: 1rem;
  cursor: pointer;
  border: 2px solid white;
  transition: all 0.2s ease-in-out;
  &:hover {
    border: 2px solid #69b1bf;
  }
`;

const AddHousehold = styled(AddContainer)``;

const Containers = styled.div`
  min-height: 12.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 2rem 0 0;
`;
const ContainersData = styled.div`
  display: flex;
  margin-top: 1.5rem;
`;

const SpinnerWrapper = styled.div`
  width: 100%;
  height: 4rem;
  padding-top: 1.5rem;
  display: flex;
  justify-content: center;
`;
export default LocationDetails;
