export const addZeroToPostalCode = (code) => {
    if (code) {
      const newPostalCode = code.toString();
      if (newPostalCode.length === 3) {
        return `0${newPostalCode}`;
      }
      return code;
    }
  };

