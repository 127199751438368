import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Admins, AdminsType } from "../../validators/Admin";
import { TagUsersType, UserType } from "../../validators/User";
import {
  ContainerType,
  ContainersType,
  LocationContainerType,
  NewContainerType,
} from "../../validators/Container";
import { Schedules, SchedulesType } from "../../validators/Schedule";
import { NodeType } from "../../validators/Node";
import {
  HouseholdType,
  Households,
  HouseholdsType,
} from "../../validators/Household";
import {
  ContainerLogResponse,
  ContainerLogResponseType,
} from "../../validators/Log";
import { Locations, LocationsType } from "../../validators/Location";
import {
  ImportTagsType,
  Tags,
  TagsType,
  TagsWithAddress,
  TagsWithAddressType,
} from "../../validators/Tag";

export const Api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL_TEST,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("API_token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: [
    "Admins",
    "Users",
    "Nodes",
    "Containers",
    "Schedule",
    "Households",
    "Log",
    "Units",
    "Tags",
  ],
  endpoints: (builder) => ({
    // GET ADMINS
    getAdmins: builder.query<AdminsType, void>({
      query: () => "administrators",
      providesTags: ["Admins"],
      // Validating respose from endpoint
      transformResponse: (data: AdminsType) => Admins.parse(data),
    }),
    // DELETE ADMINS
    deleteAdmin: builder.mutation({
      query: (id: string) => ({
        url: `administrators/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Admins"],
    }),
    // GET USERS
    getUsers: builder.query<TagUsersType, void>({
      query: () => "users",
      providesTags: ["Users"],
    }),
    // ADD USER
    addUser: builder.mutation({
      query: (body: UserType) => {
        return {
          url: `users`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Users"],
    }),
    // GET NODES
    getNodes: builder.query<NodeType[], void | string>({
      query: (locationId: string) => "nodes",
      providesTags: ["Nodes"],
    }),
    // GET CONTAINERS
    getContainers: builder.query<ContainersType, string | void>({
      query: (locationId: string) => ({
        url: "containers",
        params: { unitId: locationId },
      }),
      providesTags: ["Containers"],
    }),
    // GET CONTAINERS BY CONTAINERIDs
    getContainersByIds: builder.query<ContainersType, string | null>({
      query: (containerIds: string) => ({
        url: `containers/${containerIds}`,
      }),
      providesTags: ["Containers"],
    }),
    // ADD CONTAINER
    addContainer: builder.mutation({
      query: (body: NewContainerType[] | LocationContainerType[]) => ({
        url: `containers`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Containers"],
    }),
    // EDIT CONTAINER
    editContainer: builder.mutation<ContainerType, Partial<ContainerType>>({
      query: (data) => {
        const { Id, ...body } = data;
        return {
          url: `containers/${Id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Containers"],
    }),
    // DELETE CONTAINER
    deleteContainer: builder.mutation({
      query: ({ id, Created }) => ({
        url: `containers/${id}`,
        params: { Created: Created },
        method: "DELETE",
      }),
      invalidatesTags: ["Containers"],
    }),
    // GET SCHEDULE
    getSchedule: builder.query<SchedulesType, string>({
      query: (id) => {
        return `schedules/${id}`;
      },
      providesTags: ["Schedule"],
      // Validating respose from endpoint
      transformResponse: (data: SchedulesType) => Schedules.parse(data),
    }),
    // ADD SCHEDULE
    addSchedule: builder.mutation<SchedulesType, Partial<SchedulesType>>({
      query: (data: SchedulesType) => {
        const { Id, Created, ...body } = data[0];

        return {
          url: `schedules/${Id}`,
          method: "POST",
          body: [body],
        };
      },
      invalidatesTags: ["Containers"],
    }),
    // GET HOUSEHOLDS
    getHouseholds: builder.query<HouseholdsType, string | void>({
      query: (id) => {
        return `households/${id}`;
      },
      providesTags: ["Households"],
      // Validating respose from endpoint
      transformResponse: (data: HouseholdsType) => Households.parse(data),
    }),
    // EDIT HOUSEHOLDS
    editHousehold: builder.mutation<
      HouseholdType,
      { householdId: string; Containers: string }
    >({
      query: (data) => {
        const { householdId, ...body } = data;
        return {
          url: `households/${householdId}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Households"],
    }),
    // GET LOGS
    getLogs: builder.query<
      ContainerLogResponseType,
      { id: string; index: number; count: number; type: string }
    >({
      query: (data) => {
        const { id, index, count, type } = data;
        return {
          url: `log/${id}`,
          params: {
            index: index,
            count: count,
            type: type,
          },
        };
      },
      providesTags: ["Log"],
      // Validating respose from endpoint
      transformResponse: (data: ContainerLogResponseType) =>
        ContainerLogResponse.parse(data),
    }),
    // GET LOCATIONS/UNITS
    getLocations: builder.query<LocationsType, string | void>({
      query: (id) => {
        return {
          url: `units/${id}`,
        };
      },
      providesTags: ["Units"],
      // Validating respose from endpoint
      transformResponse: (data: LocationsType) => Locations.parse(data),
    }),

    // GET TAGS
    getTags: builder.query<TagsType | TagsWithAddressType, void>({
      query: () => {
        return {
          url: `tags`,
        };
      },
      providesTags: ["Tags"],
      // Validating respose from endpoint
      transformResponse: (data: TagsType) => Tags.parse(data),
    }),
    // GET TAGS WITH ADDRESS
    getTagsWithAddress: builder.query<TagsWithAddressType, void>({
      query: () => {
        return {
          url: `tags`,
          //params: { addAddress: true }, //Disabled because loading the addresses with this flag caused one and one entry to be populated with address from household in backend
        };
      },
      providesTags: ["Tags"],
      // Validating respose from endpoint
      transformResponse: (data: TagsWithAddressType) =>
        TagsWithAddress.parse(data),
    }),
    // ADD TAGS
    addTags: builder.mutation<TagsWithAddressType, ImportTagsType>({
      query: (data: ImportTagsType) => {
        return {
          url: `tags`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["Tags"],
    }),
    // DELETE TAG
    deleteTag: builder.mutation({
      query: ({ id }) => ({
        url: `tags/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Tags"],
    }),
  }),
  refetchOnMountOrArgChange: true,
});

export const {
  useGetAdminsQuery,
  useDeleteAdminMutation,
  useGetUsersQuery,
  useAddUserMutation,
  useGetNodesQuery,
  useGetContainersQuery,
  useGetContainersByIdsQuery,
  useAddContainerMutation,
  useEditContainerMutation,
  useDeleteContainerMutation,
  useGetScheduleQuery,
  useAddScheduleMutation,
  useGetHouseholdsQuery,
  useEditHouseholdMutation,
  useGetLogsQuery,
  useGetLocationsQuery,
  useGetTagsQuery,
  useGetTagsWithAddressQuery,
  useAddTagsMutation,
  useDeleteTagMutation,
} = Api;
