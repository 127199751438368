export const custom_date = (d) => {
  let startDate = new Date(d * 1000);
  let day = startDate.getDate().toString().padStart(2, '0');
  let month = (startDate.getMonth() + 1).toString().padStart(2, '0');
  let year = startDate.getFullYear().toString();

  let hour = startDate.getHours().toString().padStart(2, '0');
  let minute = startDate.getMinutes().toString().padStart(2, '0');

  const fullTimeStamp = `${day}-${month}-${year} ${hour}:${minute}`;

  return fullTimeStamp;
};



