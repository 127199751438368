import React, { useEffect, useState } from "react";
import { colors } from "../../styles/variables";
import {
  Text,
  Button,
  Input,
  Flex,
  Box,
  useDisclosure,
  useToast,
  Spinner,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import ExportToExcelTags from "./ExportToExcelTags";
import { AddIcon } from "@chakra-ui/icons";
import TagTable from "./TagTable";
import {
  useAddTagsMutation,
  useGetTagsWithAddressQuery,
} from "../../features/api/ApiSlice";
import { ImportTags, ImportTagsType } from "../../validators/Tag";
import ImportAlert from "../ImportAlert";
import { ImportError } from "../../types/importError";
import importDataFromExcel from "../../utilities/importFromExcel";

const Tags = () => {
  const fileName = "Brikker";
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const [importErrorMessage, setImportErrorMessage] = useState<ImportError[]>(
    []
  );
  const {
    data: tagsData,
    isLoading: isTagsLoading,
    isError: isTagsError,
  } = useGetTagsWithAddressQuery();
  const [
    addTags,
    {
      isLoading: addTagsIsLoading,
      error: addTagsError,
      isSuccess: addTagsIsSuccess,
    },
  ] = useAddTagsMutation();
  const toast = useToast();

  // Handle actions on import success
  useEffect(() => {
    if (addTagsIsSuccess) {
      toast.close("Loading");
      toast({
        title: "Brikker lagret",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [addTagsIsSuccess, toast]);

  // Handle actions on import waiting
  useEffect(() => {
    if (addTagsIsLoading) {
      toast({
        id: "Loading",
        title: "Brikker lagres",
        status: "info",
        isClosable: false,
      });
    }
  }, [addTagsIsLoading, toast]);

  // Handle actions on import waiting
  useEffect(() => {
    if (addTagsError) {
      toast({
        title: "Brikker kunne ikke lagres",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [addTagsError, toast]);

  const loadingSpinner = (
    <Flex
      pt={"2rem"}
      w={"100%"}
      h={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Spinner size="xl" color={colors.greenMain} />
    </Flex>
  );

  const tagsError = (
    <Flex
      pt={"1.5rem"}
      w={"100%"}
      h={"100%"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Alert
        status="error"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        height="200px"
        maxW={"600px"}
        borderRadius={5}
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          Oops, noe har gått galt!
        </AlertTitle>
        <AlertDescription maxWidth="sm">
          Kunne ikke hente brikker. Venligst prøv igjen, eller kontakt en
          administrator.
        </AlertDescription>
      </Alert>
    </Flex>
  );

  const tagTable = (
    <>
      <Flex my={"2rem"}>
        <Button
          variant={"outline"}
          colorScheme="blue"
          size={"xs"}
          rightIcon={<AddIcon />}
          mx={1}
          onChange={(e: any) => {
            const file = e.target.files[0];
            importDataFromExcel<ImportTagsType>(
              file,
              ImportTags,
              setImportErrorMessage,
              onOpen,
              addTags
            );
          }}
          onClick={(e: any) => (e.target.value = null)}
        >
          <label style={{ cursor: "pointer" }}>
            <Input type="file" display="none" />
            Importer fra fil
          </label>
        </Button>
        <ExportToExcelTags tableData={tagsData} fileName={fileName} />
      </Flex>
      <TagTable
        tagsList={tagsData ?? []}
        noTagsMessage={"Ingen brikker ble funnet"}
      />
    </>
  );

  return (
    <Flex width={"100%"} bg={`${colors.greyLight}`} flex={1}>
      <Box bg={"white"} p={"2.5rem"} m={"2rem"} width={"100%"} maxW={"1200px"}>
        <Text fontSize={"2xl"} as={"h1"} fontWeight={"600"}>
          Brikker
        </Text>
        <hr style={{ borderTop: "1px solid lightgrey" }} />
        {isTagsLoading ? loadingSpinner : tagsData ? tagTable : tagsError}
      </Box>
      <ImportAlert
        title={"Validering av importfilen har feilet"}
        errorText={importErrorMessage}
        buttonText={"Ok"}
        isOpen={isOpen}
        cancelRef={cancelRef}
        onClose={onClose}
      />
    </Flex>
  );
};

export default Tags;
