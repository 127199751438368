import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { colors } from "../../styles/variables";
import {
  Button,
  Spinner,
  Table,
  Tag,
  TagLabel,
  TagRightIcon,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Stack,
  HStack,
  Input,
} from "@chakra-ui/react";
import axios from "axios";

import ExportToExcelAppUsers from "./ExportToExcelAppUsers";
import { ReactComponent as Sorting } from "../../assets/icons/Sorting.svg";
import UserRow from "./UserRow";

const UsersHousehold = ({ householdId }) => {
  const [appUsers, setAppUsers] = useState("Initial State");
  const [order, setOrder] = useState("ASC");
  const token = localStorage.getItem("API_token");

  const fileName = "app_brukere_boenhet_";
  let headers = {
    authorization: `Bearer ${token}`,
  };
  const getAppUsers = () => {
    axios
      .get(
        `https://gpshu4lon5.execute-api.eu-north-1.amazonaws.com/Test/users?householdId=${householdId}`,
        { headers: headers }
      )
      .then((res) => setAppUsers(res.data))
      .catch((err) => setAppUsers("Error"));
  };
  useEffect(() => {
    getAppUsers();
  }, []);

  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...appUsers].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setAppUsers(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...appUsers].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setAppUsers(sorted);
      setOrder("ASC");
    }
  };

  return (
    <>
      <ControlTableButtonsContainer>
        {/* same export component as for export tags*/}
        <ExportToExcelAppUsers tableData={appUsers} fileName={fileName} />
      </ControlTableButtonsContainer>
      <TableMainContainer>
        <Table variant="simple" size="sm" colorScheme={"teal"}>
          <Thead>
            <Tr>
              <Th>
                <ThWrapper>
                  <TableHeader>Navn</TableHeader>
                  <SortingIcon onClick={() => sorting("Name")} />
                </ThWrapper>
              </Th>
              <Th>
                <ThWrapper>
                  <TableHeader>Adresse</TableHeader>
                  <SortingIcon onClick={() => sorting("StreetName")} />
                </ThWrapper>
              </Th>
              <Th>
                <ThWrapper>
                  <TableHeader>Sted</TableHeader>
                  <SortingIcon onClick={() => sorting("PostalCode")} />
                </ThWrapper>
              </Th>
              <Th>
                <ThWrapper>
                  <TableHeader>Epost</TableHeader>
                  <SortingIcon onClick={() => sorting("Email")} />
                </ThWrapper>
              </Th>
              <Th>
                <ThWrapper>
                  <TableHeader>Brikke-id</TableHeader>
                  <SortingIcon onClick={() => sorting("TagId")} />
                </ThWrapper>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {appUsers !== "Error" &&
              appUsers !== "Initial State" &&
              appUsers.map((user, index) => (
                <UserRow key={index} user={user} />
              ))}
          </Tbody>
        </Table>
        {appUsers === "Initial State" && (
          <SpinnerWrapper>
            <Spinner size="lg" color={colors.greenMain} />
          </SpinnerWrapper>
        )}
      </TableMainContainer>
    </>
  );
};

const Wrapper = styled.div`
  height: 92.5vh;
  display: flex;
  flex: 1;
  overflow: auto;
  background-color: ${colors.greyLight};
`;

const Content = styled.div`
  font-size: 1.25rem;
  padding: 2.5rem;
  margin: 2rem;
  border-radius: 5px;
  font-family: "Roboto";
  background-color: white;
  height: auto;
  overflow: auto;
`;

const ControlTableButtonsContainer = styled.div`
  margin-top: 2rem;
  width: 20rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TableMainContainer = styled(TableContainer)`
  margin-top: 3rem;
`;
const TableHeader = styled(Text)`
  font-size: 0.6rem;
  font-weight: bold;
`;
const ThWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SortingIcon = styled(Sorting)`
  width: 1rem;
  height: 1rem;
  margin-left: 0.75rem;
  cursor: pointer;
`;
const SpinnerWrapper = styled.div`
  width: 100%;
  height: 4rem;
  padding-top: 1.5rem;
  display: flex;
  justify-content: center;
`;

export default UsersHousehold;
