const sortColumn = <Type>(
  colName: string,
  order: string,
  setOrder: (val: string) => void,
  setList: (val: Type[]) => void,
  list: Type[]
) => {
  if (order === "ASC") {
    const sorted = [...list].sort((a, b) => {
      console.log(a["PostalCode" as keyof Type]);
      console.log(a[colName as keyof Type]);
      return (a[colName as keyof Type] ?? "z") >
        (b[colName as keyof Type] ?? "z")
        ? 1
        : -1;
    });
    setList(sorted);
    setOrder("DSC");
  }
  if (order === "DSC") {
    const sorted = [...list].sort((a, b) =>
      (a[colName as keyof Type] ?? "z") < (b[colName as keyof Type] ?? "z")
        ? 1
        : -1
    );
    setList(sorted);
    setOrder("ASC");
  }
};

export default sortColumn;
