import styled from "styled-components";
import { colors, fonts } from "../styles/variables";
import { NavLink } from "react-router-dom";
import { ReactComponent as Home } from "../assets/icons/Home.svg";
import { ReactComponent as Admins } from "../assets/icons/Admins.svg";
import { ReactComponent as Users } from "../assets/icons/Users.svg";
import { ReactComponent as Containers } from "../assets/icons/Containers.svg";
import { ReactComponent as Profile } from "../assets/icons/Profile.svg";
import { ReactComponent as Lock } from "../assets/icons/Lock-icon.svg";
import { ReactComponent as Locasions } from "../assets/icons/Location-icon.svg";
import { ReactComponent as Households } from "../assets/icons/Households-icon.svg";
import { ReactComponent as Tags } from "../assets/icons/Tags.svg";
import { URL_NAMES } from "../types/urlNames";

const Sidebar = () => {
  return (
    <SideBarNavigation>
      <NavigationList>
        <NavigationEl>
          <NavigationLink
            activeStyle={{
              color: `${colors.greenLight}`,
              transition: "all 0.1s linear",
            }}
            to={URL_NAMES.HOME}
            exact
          >
            <IconHome />
            <span>Hjem</span>
          </NavigationLink>
        </NavigationEl>
        <NavigationEl>
          <NavigationLink
            activeStyle={{
              color: `${colors.greenLight}`,
              transition: "all 0.1s linear",
            }}
            to={URL_NAMES.ADMINS}
          >
            <IconAdmins />
            <span>Administratorer</span>
          </NavigationLink>
        </NavigationEl>
        <NavigationEl>
          <NavigationLink
            activeStyle={{
              color: `${colors.greenLight}`,
              transition: "all 0.1s linear",
            }}
            to={URL_NAMES.USERS}
          >
            <IconUsers />
            <span>App-brukere</span>
          </NavigationLink>
        </NavigationEl>
        <NavigationEl>
          <NavigationLink
            activeStyle={{
              color: `${colors.greenLight}`,
              transition: "all 0.1s linear",
            }}
            to={URL_NAMES.LOCATIONS}
          >
            <IconLocations />
            <span>Lokasjoner</span>
          </NavigationLink>
        </NavigationEl>
        <NavigationEl>
          <NavigationLink
            activeStyle={{
              color: `${colors.greenLight}`,
              transition: "all 0.1s linear",
            }}
            to={URL_NAMES.HOUSING_UNITS}
          >
            <IconHouseHolds />
            <span>Boenheter</span>
          </NavigationLink>
        </NavigationEl>
        <NavigationEl>
          <NavigationLink
            activeStyle={{
              color: `${colors.greenLight}`,
              transition: "all 0.1s linear",
            }}
            to={URL_NAMES.CONTAINERS}
          >
            <IconContainers />
            <span>Containere</span>
          </NavigationLink>
        </NavigationEl>
        <NavigationEl>
          <NavigationLink
            activeStyle={{
              color: `${colors.greenLight}`,
              transition: "all 0.1s linear",
            }}
            to={URL_NAMES.LOCK_NODES}
          >
            <IconLock />
            <span>Lås</span>
          </NavigationLink>
        </NavigationEl>
        <NavigationEl>
          <NavigationLink
            activeStyle={{
              color: `${colors.greenLight}`,
              transition: "all 0.1s linear",
            }}
            to={URL_NAMES.TAGS}
          >
            <IconTags />
            <span>Brikker</span>
          </NavigationLink>
        </NavigationEl>
        <NavigationEl>
          <NavigationLink
            activeStyle={{
              color: `${colors.greenLight}`,
              transition: "all 0.1s linear",
            }}
            to={URL_NAMES.PROFILE}
          >
            <IconProfile />
            <span>Profil</span>
          </NavigationLink>
        </NavigationEl>
      </NavigationList>
    </SideBarNavigation>
  );
};

const SideBarNavigation = styled.nav`
  height: calc(100%-5rem);
  min-width: 15rem;
  background-color: ${colors.blue};
`;

const NavigationList = styled.ul`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
`;

const NavigationEl = styled.li`
  transition: all 0.2s linear;
  &:hover {
    background-color: ${colors.greyLight};
  }
`;

const IconHome = styled(Home)`
  width: 3rem;
  margin: 0 0.75rem 0 0.75rem;
`;
const IconAdmins = styled(Admins)`
  width: 3rem;
  margin: 0 0.75rem 0 0.75rem;
`;

const IconUsers = styled(Users)`
  width: 3rem;
  margin: 0 0.75rem 0 0.75rem;
`;

const IconContainers = styled(Containers)`
  width: 3rem;
  margin: 0 0.75rem 0 0.75rem;
`;

const IconLocations = styled(Locasions)`
  width: 3rem;
  margin: 0 0.75rem 0 0.75rem;
`;
const IconHouseHolds = styled(Households)`
  width: 3rem;
  margin: 0 0.75rem 0 0.75rem;
`;

const IconLock = styled(Lock)`
  width: 3rem;
  margin: 0 0.75rem 0 0.75rem;
`;
const IconTags = styled(Tags)`
  width: 3rem;
  margin: 0 0.75rem 0 0.75rem;
`;
const IconProfile = styled(Profile)`
  width: 3rem;
  margin: 0 0.75rem 0 0.75rem;
`;

const NavigationLink = styled(NavLink)`
  text-decoration: none;
  font-family: "Roboto";
  font-size: 0.85rem;
  font-weight: 100;
  color: ${colors.white};
  padding: 1rem 0 1rem 0;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export default Sidebar;
