import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../styles/variables";
import axios from "axios";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  TableContainer,
  Spinner,
  Tag,
  TagLabel,
} from "@chakra-ui/react";
import Log from "../Log";
import {
  useParams,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { ReactComponent as ExclamationMark } from "../../assets/icons/ExclamationMark.svg";
import Pagination from "../Pagination";
const NodeLog = () => {
  const [nodeLog, setNodelog] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [logsPerPage, setLogsPerPage] = useState(10);
  const { id } = useParams();
  const location = useLocation();
  const { nodeId } = location?.state;
  const token = localStorage.getItem("API_token");
  let headers = {
    authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    axios
      .get(
        `https://gpshu4lon5.execute-api.eu-north-1.amazonaws.com/Test/log/${nodeId}/?index=0&count=50&type=node`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        setNodelog(res.data.events);
      })
      .catch((err) => console.log(err));
  }, [nodeId]);

  const indexOfLastLog = currentPage * logsPerPage;
  const indexOfFirstLog = indexOfLastLog - logsPerPage;
  const currentLogs = nodeLog?.slice(indexOfFirstLog, indexOfLastLog);

  const paginate = (pageNum) => {
    setCurrentPage(pageNum);
  };

  return (
    <Wrapper>
      <Content>
        <Title>{id} logg</Title> <BreakingLine />
        <Warningwrapper>
          {currentLogs?.length === 0 && (
            <Tag variant="outline" size="md">
              <TagRightIconEl boxsize="12px" as={ExclamationMark} />
              <TagLabel>Det finnes ingen hendelser under denne låset</TagLabel>
            </Tag>
          )}
        </Warningwrapper>
        <TableMainContainer>
          <Table variant="simple" size="sm" colorScheme={"teal"}>
            <Thead>
              <Tr>
                <Th>Type</Th>
                <Th>Opprettet</Th>
                <Th>Tag</Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentLogs?.map((log) => (
                <Log
                  key={log.Id}
                  tag={log.Tag}
                  eventType={log.Type}
                  created={log.Created}
                />
              ))}
            </Tbody>
          </Table>
          {!currentLogs && (
            <SpinnerWrapper>
              <Spinner size="lg" color={colors.greenMain} />
            </SpinnerWrapper>
          )}
        </TableMainContainer>
        {currentLogs && (
          <Pagination
            logsPerPage={logsPerPage}
            totalLogs={nodeLog?.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        )}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 92.5vh;
  display: flex;
  flex: 1;
  overflow: auto;
  background-color: ${colors.greyLight};
`;

const Content = styled.div`
  width: 60%;
  font-size: 1.25rem;
  padding: 2.5rem;
  margin: 2rem;
  border-radius: 5px;
  font-family: "Roboto";
  background-color: white;
  height: auto;
  overflow: auto;
`;
const Title = styled.h1`
  font-weight: 600;
`;

const BreakingLine = styled.hr`
  border-top: 2px solid grey;
`;

const TableMainContainer = styled(TableContainer)`
  margin-top: 3rem;
  height: 60%;
`;
const Warningwrapper = styled.div`
  margin: 2rem 0 2rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const TagRightIconEl = styled(Tag)`
  margin-right: 0.5rem;
  width: 1.25rem;
  height: 1.25rem;
`;

const SpinnerWrapper = styled.div`
  width: 100%;
  height: 4rem;
  padding-top: 1.5rem;
  display: flex;
  justify-content: center;
`;

export default NodeLog;
