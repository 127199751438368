import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { ImportError } from "../types/importError";

interface ImportAlertProps {
  title: string;
  errorText: ImportError[];
  buttonText: string;
  isOpen: boolean;
  cancelRef: any;
  onClose: () => void;
}

const ImportAlert = ({
  title,
  errorText,
  buttonText,
  isOpen,
  cancelRef,
  onClose,
}: ImportAlertProps) => {
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>
            {errorText.map((error) => (
              <Flex flexDir={"column"} py={2}>
                <Flex>
                  <Text fontSize={14} fontWeight={"semibold"}>
                    {error.type} har feil format på linjer:
                  </Text>
                </Flex>
                <Flex>
                  <Text fontSize={14}>
                    {" [ "}
                    {error.lines.map((line, i) =>
                      i + 1 !== error.lines.length ? `${line}, ` : line
                    )}
                    {" ] "}
                  </Text>
                </Flex>
              </Flex>
            ))}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button colorScheme="teal" onClick={onClose} ml={3}>
              {buttonText}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default ImportAlert;
