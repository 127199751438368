import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Button,
  Input,
  Flex,
  useToast,
  Switch,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { EditContainer, EditContainerType } from "../../validators/Container";
import { useAddContainerMutation } from "../../features/api/ApiSlice";
import { NodeType } from "../../validators/Node";

interface AddContainerModalProps {
  isOpen: boolean;
  onClose: () => void;
  nodes: NodeType[];
  locationId: string;
}

const AddContainerModal = ({
  isOpen,
  onClose,
  nodes,
  locationId,
}: AddContainerModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EditContainerType>({
    resolver: zodResolver(EditContainer),
    shouldUnregister: true,
    defaultValues: {
      StartUp: `${new Date().toLocaleDateString("en-CA")}T01:00`,
    },
  });
  const [addContainer, { isLoading, isSuccess }] = useAddContainerMutation();
  const toast = useToast();

  const optionNodesList = nodes
    .filter((node) => !node.ContainerId)
    .map((node: NodeType) => (
      <option value={node.ThingName ?? "Ukjent"}>{node.ThingName}</option>
    ));

  // Handle actions on create success
  useEffect(() => {
    if (isSuccess) {
      onClose();
      toast({
        title: "Container opprettet",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [isSuccess, onClose, toast]);

  return (
    <Modal
      isCentered
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Ny Container</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form>
            {/* Name */}
            <FormControl isInvalid={!!errors.Name?.message}>
              <FormLabel>Navn</FormLabel>
              <Input autoComplete="off" {...register("Name")} />
              <FormErrorMessage>
                {errors.Name?.message?.toString()}
              </FormErrorMessage>
            </FormControl>

            <Flex mt={2}>
              {/* Fraction */}
              <FormControl mr={2}>
                <FormLabel>Fraksjon</FormLabel>
                <Input
                  autoComplete="off"
                  {...register("Fraction", {
                    setValueAs: (frac) => (frac === "" ? null : frac),
                  })}
                />
                <FormErrorMessage>
                  {errors.Fraction?.message?.toString()}
                </FormErrorMessage>
              </FormControl>

              {/* Type */}
              <FormControl ml={2}>
                <FormLabel>Innkasttype</FormLabel>
                <Input
                  autoComplete="off"
                  {...register("Type", {
                    setValueAs: (type) => (type === "" ? null : type),
                  })}
                />
                <FormErrorMessage>
                  {errors.Type?.message?.toString()}
                </FormErrorMessage>
              </FormControl>
            </Flex>

            <Flex mt={2}>
              {/* Size */}
              <FormControl mr={2}>
                <FormLabel>Størrelse</FormLabel>
                <Input
                  autoComplete="off"
                  {...register("Size", {
                    setValueAs: (size) => (size === "" ? null : size),
                  })}
                />
                <FormErrorMessage>
                  {errors.Size?.message?.toString()}
                </FormErrorMessage>
              </FormControl>

              {/* Coordinates */}
              <FormControl pl={2}>
                <FormLabel>Koordinater</FormLabel>
                <Input {...register("Coordinates")} />
                <FormErrorMessage>
                  {errors.Coordinates?.message?.toString()}
                </FormErrorMessage>
              </FormControl>
            </Flex>

            <Flex mt={2}>
              {/* Start time */}
              <FormControl mr={2} isInvalid={!!errors.StartUp?.message}>
                <FormLabel>Oppstart av drift</FormLabel>
                <Input
                  type="datetime-local"
                  autoComplete="off"
                  {...register("StartUp")}
                />
                <FormErrorMessage>
                  {errors.StartUp?.message?.toString()}
                </FormErrorMessage>
                {}
              </FormControl>

              {/* Active Status */}
              <FormControl ml={2} isInvalid={!!errors.Active?.message}>
                <FormLabel>Driftsstatus</FormLabel>
                <Flex
                  w={"100%"}
                  h={"40px"}
                  justifyContent={"space-evenly"}
                  alignItems={"center"}
                >
                  Inaktiv
                  <Switch size="md" {...register("Active")} />
                  Aktiv
                </Flex>

                <FormErrorMessage>
                  {errors.Active?.message?.toString()}
                </FormErrorMessage>
              </FormControl>
            </Flex>

            {/* Node */}
            <FormControl mt={2}>
              <FormLabel>Låseenhet</FormLabel>
              <Select
                disabled={optionNodesList.length === 0}
                placeholder={
                  optionNodesList.length === 0
                    ? "Ingen tilgjengelige låser"
                    : "Velg en låseenhet"
                }
                {...register("Node", {
                  setValueAs: (node) => (node === "" ? null : node),
                  required: false,
                  value: null,
                })}
              >
                {optionNodesList}
              </Select>
              <FormErrorMessage>
                {errors.Node?.message?.toString()}
              </FormErrorMessage>
            </FormControl>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            isLoading={isLoading}
            colorScheme="teal"
            mr={3}
            onClick={handleSubmit((container) => {
              addContainer([
                {
                  ...container,
                  UnitId: locationId,
                  StartUp: Number(new Date(container.StartUp)) / 1000,
                },
              ]);
            })}
          >
            Lagre
          </Button>
          <Button
            variant="ghost"
            colorScheme="teal"
            mr={3}
            onClick={() => onClose()}
          >
            Avbryt
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddContainerModal;
