import React, { useState, useEffect } from "react";
import { colors } from "../../styles/variables";
import {
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  TableContainer,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  Flex,
  Box,
  Heading,
} from "@chakra-ui/react";

import ExportToExcelAppUsers from "./ExportToExcelAppUsers";
import { ReactComponent as Sorting } from "../../assets/icons/Sorting.svg";
import { useGetUsersQuery } from "../../features/api/ApiSlice";
import AddUserModalContent from "./AddUserModalContent";
import { TagUserType, TagUsersType } from "../../validators/User";
import UserRow from "./UserRow";

const Users = () => {
  const { data, isError, isLoading, isSuccess } = useGetUsersQuery();
  const [usersList, setUsersList] = useState<TagUsersType>([]);
  const [order, setOrder] = useState("ASC");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const fileName = "app_brukere";

  useEffect(() => {
    if (isSuccess && data) {
      setUsersList(data);
    }
  }, [data, isSuccess]);

  const sorting = (colName: string) => {
    if (order === "ASC") {
      const sorted = [...usersList].sort((a, b) => {
        return (a[colName as keyof TagUserType] ?? "z") >
          (b[colName as keyof TagUserType] ?? "z")
          ? 1
          : -1;
      });
      setUsersList(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...usersList].sort((a, b) =>
        (a[colName as keyof TagUserType] ?? "z") <
        (b[colName as keyof TagUserType] ?? "z")
          ? 1
          : -1
      );
      setUsersList(sorted);
      setOrder("ASC");
    }
  };

  return (
    <Flex
      h={"92.5vh"}
      flex={"1"}
      overflow={"auto"}
      bgColor={`${colors.greyLight}`}
    >
      <Box
        fontSize={"1.25rem"}
        p={"2.5rem"}
        m={"2rem"}
        borderRadius={"5px"}
        fontFamily={"Roboto"}
        bgColor={"white"}
        h={"auto"}
        overflow={"auto"}
      >
        <Heading as={"h2"} size={"md"} fontFamily={"Roboto"} fontWeight={600}>
          App-brukere
        </Heading>
        <hr style={{ borderTop: "1px solid lightgrey" }} />

        {isError ? (
          <Text>Oops! Noe har gått galt. Venligst forsøk igjen senere.</Text>
        ) : isLoading ? (
          <Flex
            width={"100%"}
            height={"4rem"}
            pt={"1.5rem"}
            justifyContent={"center"}
          >
            <Spinner size="lg" color={colors.greenMain} />
          </Flex>
        ) : (
          <Box>
            <Flex
              mt={"2rem"}
              w={"23rem"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              {/*TODO: Add functionality */}
              {/* <Button
                size="xs"
                variant={"outline"}
                colorScheme="teal"
                onClick={onOpen}
                rightIcon={<AddIcon />}
              >
                Ny app-bruker
              </Button> */}

              <ExportToExcelAppUsers
                tableData={usersList}
                fileName={fileName}
              />
            </Flex>
            <Modal
              isCentered
              closeOnOverlayClick={false}
              isOpen={isOpen}
              onClose={onClose}
            >
              <ModalOverlay />
              <ModalContent>
                <AddUserModalContent closeModal={onClose} />
              </ModalContent>
            </Modal>
            <TableContainer mt={"3rem"}>
              <Table variant="simple" size="sm" colorScheme={"teal"}>
                <Thead>
                  <Tr>
                    <Th>
                      <Flex alignItems={"center"}>
                        <Text fontSize={"0.6rem"} fontWeight={"bold"}>
                          Navn
                        </Text>
                        <Sorting
                          style={{
                            width: "1rem",
                            height: "1rem",
                            marginLeft: "0.75rem",
                            cursor: "pointer",
                          }}
                          onClick={() => sorting("Name")}
                        />
                      </Flex>
                    </Th>
                    <Th>
                      <Flex alignItems={"center"}>
                        <Text fontSize={"0.6rem"} fontWeight={"bold"}>
                          Adresse
                        </Text>

                        <Sorting
                          style={{
                            width: "1rem",
                            height: "1rem",
                            marginLeft: "0.75rem",
                            cursor: "pointer",
                          }}
                          onClick={() => sorting("StreetName")}
                        />
                      </Flex>
                    </Th>
                    <Th>
                      <Flex alignItems={"center"}>
                        <Text fontSize={"0.6rem"} fontWeight={"bold"}>
                          Sted
                        </Text>
                        <Sorting
                          style={{
                            width: "1rem",
                            height: "1rem",
                            marginLeft: "0.75rem",
                            cursor: "pointer",
                          }}
                          onClick={() => sorting("PostalCode")}
                        />
                      </Flex>
                    </Th>
                    <Th>
                      <Flex alignItems={"center"}>
                        <Text fontSize={"0.6rem"} fontWeight={"bold"}>
                          Epost
                        </Text>

                        <Sorting
                          style={{
                            width: "1rem",
                            height: "1rem",
                            marginLeft: "0.75rem",
                            cursor: "pointer",
                          }}
                          onClick={() => sorting("Email")}
                        />
                      </Flex>
                    </Th>
                    <Th>
                      <Flex alignItems={"center"}>
                        <Text fontSize={"0.6rem"} fontWeight={"bold"}>
                          Brikke-id
                        </Text>

                        <Sorting
                          style={{
                            width: "1rem",
                            height: "1rem",
                            marginLeft: "0.75rem",
                            cursor: "pointer",
                          }}
                          onClick={() => sorting("TagId")}
                        />
                      </Flex>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {usersList.map((user, index) => (
                    <UserRow key={index} user={user} />
                  ))}
                </Tbody>
              </Table>
              {usersList.length === 0 && (
                <Flex
                  h={"4rem"}
                  w={"100%"}
                  pt={"1.5rem"}
                  justifyContent={"center"}
                >
                  <Spinner size="lg" color={colors.greenMain} />
                </Flex>
              )}
            </TableContainer>
          </Box>
        )}
      </Box>
    </Flex>
  );
};

export default Users;
