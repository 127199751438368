import { createSlice } from "@reduxjs/toolkit";

const initialNodesState = {
  nodes: [],
};

export const NodesSlice = createSlice({
  name: "nodes",
  initialState: initialNodesState,
  reducers: {
    updateNodes: (state, action) => {
      return action.payload;
    },
  },
});

export const { updateNodes } = NodesSlice.actions;
export default NodesSlice.reducer;
