import { z } from "zod";

const LogType = z.enum([
  "Node_Report_Unlocked",
  "Node_Report_Unlocked_Buffered",
  "Node_Report_Lid",
  "Node_Report_Status",
  "Node_Report_Battery",
  "Tag_Added",
  "Tag_Removed",
  "Node_Authorize_Request",
  "App_Unlock_Info",
  "NODE_NOT_ALIVE"
]);

const ContainerLog = z.object({
  Type: LogType,
  UserId: z.string().nullable(),
  ContainerId: z.string().nullable(),
  NodeId: z.string().nullable(),
  Description: z.string().nullable(),
  Tag: z.string().nullable(),
  Id: z.string().nullable(),
  Created: z.number().nullable(),
});

const ContainerLogs = z.array(ContainerLog);

const ContainerLogResponse = z.object({
  events: ContainerLogs,
  remaining: z.number(),
});

export { ContainerLog, ContainerLogs, ContainerLogResponse };
export type ContainerLogType = z.infer<typeof ContainerLog>;
export type ContainerLogsType = z.infer<typeof ContainerLogs>;
export type ContainerLogResponseType = z.infer<typeof ContainerLogResponse>;
