import React, { useState, useEffect } from "react";

import {
  Button,
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  VStack,
  Wrap,
  WrapItem,
  useToast,
} from "@chakra-ui/react";
import {
  useAddScheduleMutation,
  useGetScheduleQuery,
} from "../../features/api/ApiSlice";
import { colors } from "../../styles/variables";
import ScheduleDay from "./ScheduleDay";
import { ScheduleDayType, ScheduleType } from "../../validators/Schedule";
import { MODE } from "../../types/modeEnum";

interface ScheduleModalProps {
  isOpen: boolean;
  onClose: () => void;
  containerId: string;
  setDisplayModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ScheduleModal = ({
  isOpen,
  onClose,
  containerId,
  setDisplayModal,
}: ScheduleModalProps): JSX.Element => {
  const {
    data: scheduleData,
    isFetching: isFetchingSchedule,
    isLoading: isLoadingSchedule,
    error: scheduleError,
  } = useGetScheduleQuery(containerId);
  const [addSchedule, { isLoading, isSuccess, isError: isAddScheduleError }] =
    useAddScheduleMutation();
  const toast = useToast();
  const [newSchedule, setNewSchedule] = useState<ScheduleType>();
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const scheduleNotFound =
    scheduleError && "data" in scheduleError && scheduleError.status === 404;
  const loadAndSync = isLoadingSchedule || (isFetchingSchedule && scheduleData);

  const brandNewSchedule = {
    Id: containerId,
    Created: Number(new Date()) / 1000,
    Monday: {
      Mode: null,
      Default: MODE.authorized_cards,
      End: null,
      Start: null,
    },
    Tuesday: {
      Mode: null,
      Default: MODE.authorized_cards,
      End: null,
      Start: null,
    },
    Wednesday: {
      Mode: null,
      Default: MODE.authorized_cards,
      End: null,
      Start: null,
    },
    Thursday: {
      Mode: null,
      Default: MODE.authorized_cards,
      End: null,
      Start: null,
    },
    Friday: {
      Mode: null,
      Default: MODE.authorized_cards,
      End: null,
      Start: null,
    },
    Saturday: {
      Mode: null,
      Default: MODE.authorized_cards,
      End: null,
      Start: null,
    },
    Sunday: {
      Mode: null,
      Default: MODE.authorized_cards,
      End: null,
      Start: null,
    },
  };

  //   Set new schedule variable data on fetched success
  useEffect(() => {
    if (scheduleData && !isFetchingSchedule) {
      setNewSchedule(scheduleData[0]);
    }
  }, [scheduleData, containerId, isFetchingSchedule, scheduleNotFound]);

  // TODO: NEW ERROR MESSAGE from backend
  //   Handle error respose from API
  useEffect(() => {
    if (isAddScheduleError) {
      setShowErrorMessage(true);
    }
  }, [isAddScheduleError]);

  // Handle actions on create success
  useEffect(() => {
    if (isSuccess) {
      onClose();
      toast({
        title: "Timeplan oppdatert",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setDisplayModal(false);
    }
  }, [isSuccess, onClose, toast, setDisplayModal]);

  //   TODO: Generic update day function
  const changeMonday = (mondaySchedule: ScheduleDayType) => {
    if (newSchedule) {
      setNewSchedule({ ...newSchedule, Monday: mondaySchedule });
    }
  };
  const changeTuesday = (tuesdaySchedule: ScheduleDayType) => {
    if (newSchedule) {
      setNewSchedule({ ...newSchedule, Tuesday: tuesdaySchedule });
    }
  };
  const changeWednesday = (wednesdaySchedule: ScheduleDayType) => {
    if (newSchedule) {
      setNewSchedule({ ...newSchedule, Wednesday: wednesdaySchedule });
    }
  };
  const changeThursday = (thursdaySchedule: ScheduleDayType) => {
    if (newSchedule) {
      setNewSchedule({ ...newSchedule, Thursday: thursdaySchedule });
    }
  };
  const changeFriday = (fridaySchedule: ScheduleDayType) => {
    if (newSchedule) {
      setNewSchedule({ ...newSchedule, Friday: fridaySchedule });
    }
  };
  const changeSaturday = (saturdaySchedule: ScheduleDayType) => {
    if (newSchedule) {
      setNewSchedule({ ...newSchedule, Saturday: saturdaySchedule });
    }
  };
  const changeSunday = (sundaySchedule: ScheduleDayType) => {
    if (newSchedule) {
      setNewSchedule({ ...newSchedule, Sunday: sundaySchedule });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={""}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={"2xl"} fontWeight={"normal"}>
          Timeplan for tilgang til containeren
        </ModalHeader>
        <ModalCloseButton
          onClick={() => {
            onClose();
            setDisplayModal(false);
          }}
        />
        <ModalBody>
          {loadAndSync ? (
            <Flex
              width={"100%"}
              height={"4rem"}
              pt={"1.5rem"}
              justifyContent={"center"}
            >
              <Spinner size="lg" color={colors.greenMain} />
            </Flex>
          ) : newSchedule ? (
            <form>
              <Wrap>
                <WrapItem>
                  <ScheduleDay
                    bgColor={"lightgrey"}
                    scheduleDay={newSchedule.Monday}
                    day="Mandag"
                    changeSchedule={changeMonday}
                  />
                </WrapItem>
                <WrapItem>
                  <ScheduleDay
                    bgColor="darkgrey"
                    scheduleDay={newSchedule.Tuesday}
                    day="Tirsdag"
                    changeSchedule={changeTuesday}
                  />
                </WrapItem>
                <WrapItem>
                  <ScheduleDay
                    bgColor={`lightgrey`}
                    scheduleDay={newSchedule.Wednesday}
                    day="Onsdag"
                    changeSchedule={changeWednesday}
                  />
                </WrapItem>
                <WrapItem>
                  <ScheduleDay
                    bgColor="darkgrey"
                    scheduleDay={newSchedule.Thursday}
                    day="Torsdag"
                    changeSchedule={changeThursday}
                  />
                </WrapItem>
                <WrapItem>
                  <ScheduleDay
                    bgColor={`lightgrey`}
                    scheduleDay={newSchedule.Friday}
                    day="Fredag"
                    changeSchedule={changeFriday}
                  />
                </WrapItem>
                <WrapItem>
                  <ScheduleDay
                    bgColor="darkgrey"
                    scheduleDay={newSchedule.Saturday}
                    day="Lørdag"
                    changeSchedule={changeSaturday}
                  />
                </WrapItem>
                <WrapItem>
                  <ScheduleDay
                    bgColor={`lightgrey`}
                    scheduleDay={newSchedule.Sunday}
                    day="Søndag"
                    changeSchedule={changeSunday}
                  />
                </WrapItem>
              </Wrap>
            </form>
          ) : scheduleNotFound ? (
            <Center>
              <VStack>
                <Text fontSize={16} fontWeight={"500"}>
                  Ingen timeplan er opprettet for denne låsen
                </Text>
                <Button
                  colorScheme="teal"
                  size={"sm"}
                  onClick={() => setNewSchedule(brandNewSchedule)}
                >
                  Opprett timeplan
                </Button>
              </VStack>
            </Center>
          ) : (
            "Oops! Noe har gått galt! Sjekk at containeren har en aktiv lås tilknyttet seg og venligst prøv igjen senere."
          )}
          <Text
            textAlign={"center"}
            mt={2}
            justifySelf={"center"}
            color={"red"}
            fontWeight={"500"}
          >
            {showErrorMessage
              ? "Oops! Noe har gått galt, timeplanen kunne ikke oppdateres. Venligst prøv igjen senere eller kontakt support om problemet vedvarer"
              : null}
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button
            isDisabled={scheduleNotFound && !newSchedule}
            colorScheme="teal"
            isLoading={isLoading}
            mr={3}
            onClick={() => {
              if (newSchedule) {
                addSchedule([{ ...newSchedule, Id: containerId }]);
              }
            }}
          >
            Lagre
          </Button>
          <Button
            variant="ghost"
            onClick={() => {
              onClose();
              setDisplayModal(false);
            }}
          >
            Avbryt
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ScheduleModal;
