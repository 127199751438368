import React, { useEffect } from "react";
import { ReactComponent as Delete } from "../../assets/icons/Delete.svg";
import {
  Tr,
  Td,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  IconButton,
  ModalBody,
  Box,
  Text,
  useToast,
} from "@chakra-ui/react";

import { Flex } from "@aws-amplify/ui-react";
import { useDeleteTagMutation } from "../../features/api/ApiSlice";
import formatDate from "../../utilities/formatDate";
import { TagWithAddressType } from "../../validators/Tag";

interface TagRowProps {
  tag: TagWithAddressType;
}

const TagRow = ({ tag }: TagRowProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [deleteTag, { isLoading, isSuccess, isError }] = useDeleteTagMutation();

  // Handle actions on delete success
  useEffect(() => {
    if (isSuccess) {
      onClose();
      toast({
        title: "Brikke slettet",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [isSuccess, onClose, toast]);

  // Handle actions on delete error
  useEffect(() => {
    if (isError) {
      toast({
        title: "Brikke kunne ikke slettes",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [isError, onClose, toast]);

  return (
    <>
      <Tr>
        <Td>{tag.InternalId ?? null}</Td>
        <Td>{tag.ExternalId ?? null}</Td>
        <Td>
          {tag.StreetName} {tag.StreetNumber} {tag.Letter} {tag.Apartment}
        </Td>
        <Td>
          {tag.PostalCode} {tag.City}
        </Td>
        <Td>{tag.Owner ?? null}</Td>
        <Td>{formatDate(tag.Created) ?? null}</Td>
        <Td>
          <Button
            variant="outline"
            colorScheme="gray"
            aria-label="Delete tag"
            fontSize="10px"
            size={"xs"}
          />
        </Td>
        <Td>
          <IconButton
            variant="outline"
            colorScheme="gray"
            aria-label="Delete tag"
            fontSize="10px"
            size={"xs"}
            icon={<Delete style={{ width: "0.65rem" }} onClick={onOpen} />}
          />
        </Td>
      </Tr>
      <Modal
        motionPreset="slideInBottom"
        isCentered
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Er du sikker at du vil slette denne brikken?
          </ModalHeader>
          <ModalBody>
            <Flex>
              <Box minW={"70px"}>
                <Text fontWeight={"semibold"}>Intern id:</Text>
              </Box>
              <Box>
                <Text>{tag.InternalId}</Text>
              </Box>
            </Flex>
            <Flex>
              <Box minW={"70px"}>
                <Text fontWeight={"semibold"}>Ekstern id:</Text>
              </Box>
              <Box>
                <Text>{tag.ExternalId}</Text>
              </Box>
            </Flex>
          </ModalBody>

          <ModalCloseButton />
          <ModalFooter>
            <Button
              isLoading={isLoading}
              colorScheme="red"
              variant="outline"
              mr={3}
              onClick={() =>
                deleteTag({
                  id: tag.Id,
                })
              }
            >
              Slett
            </Button>
            <Button variant="ghost" colorScheme="teal" mr={3} onClick={onClose}>
              Avbryt
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TagRow;
