import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { colors } from "../styles/variables";
import {
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  MenuDivider,
} from "@chakra-ui/react";
import { ReactComponent as MainLogo } from "../assets/images/Logo.svg";
import { ReactComponent as ArrowDown } from "../assets/icons/ArrowDown.svg";
const Topbar = ({ signOut, user }) => {
  // const isLogged = useSelector(
  //   (state) => (state) => state.authenticationReducer.authentication
  // );

  return (
    <TopbarContainer>
      <LogoContainer>
        <MainLogo />
      </LogoContainer>
      <HeaderText>Smart Avfallshåndtering</HeaderText>
      <ProfileContainer>
        <Menu>
          <MenuButton as={Button} rightIcon={<ArrowDownEl />}>
            <ProfileContainerText>
              {user?.attributes?.email}
            </ProfileContainerText>
          </MenuButton>
          <MenuList>
            <NavLink to={"/hjem/profil"}>
              <MenuItemText>Info</MenuItemText>
            </NavLink>
            <MenuDivider />
            <MenuItemText onClick={signOut}> Logg ut</MenuItemText>
          </MenuList>
        </Menu>
      </ProfileContainer>
    </TopbarContainer>
  );
};
const TopbarContainer = styled.div`
  width: 100%;
  height: 8.5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.greenLight};
`;

const LogoContainer = styled.div`
  min-width: 15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.greenLight};
`;

const HeaderText = styled.p`
  font-family: "Roboto";
  font-size: 1.5rem;
  font-weight: 600;
  color: ${colors.blue};
  border-bottom: 2px solid ${colors.white};
`;

const ProfileContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem 0 1rem;
`;

const ProfileContainerText = styled.p`
  display: inline;
  color: ${colors.grey};
  font-size: 0.85rem;
  font-weight: 500;
  font-family: "Roboto";
  margin-right: 1rem;
`;

const ArrowDownEl = styled(ArrowDown)`
  width: 0.75rem;
  path: {
    stroke-width: 20px;
  }
`;

const MenuItemText = styled(MenuItem)`
  font-size: 0.85rem;
`;
export default Topbar;
