import styled from "styled-components";
import { colors } from "../../styles/variables";
import { ReactComponent as Delete } from "../../assets/icons/Delete.svg";
import { Tr, Td, Badge, useDisclosure } from "@chakra-ui/react";

const TagHouseholdElement = ({
  internalid,
  externalid,
  owner,
  tag,
  created,
  household,
  address,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Tr>
        <TableData>{internalid || <Badge>N/A</Badge>}</TableData>
        <TableData>{externalid || <Badge>N/A</Badge>}</TableData>
        <TableData>{address || <Badge>N/A</Badge>}</TableData>
      </Tr>
    </>
  );
};

const TableData = styled(Td)`
  &:hover {
    background-color: ${colors.greyLight};
  }
`;

const IconDelete = styled(Delete)`
  width: 0.65rem;
  height: 0.65rem;
  cursor: pointer;
`;

export default TagHouseholdElement;
