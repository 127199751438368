import React from "react";
import { Button } from "@chakra-ui/react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { AddIcon } from "@chakra-ui/icons";

const ExportToExcelContainers = ({ fileName, tableData }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (tableData, fileName) => {
    const copiedTableData = [...tableData];
    const mappedArray = copiedTableData.map((obj) => {
      const newObj = { ...obj };
      Object.keys(newObj).forEach((key) => {
        if (newObj[key] === null) {
          newObj[key] = "";
        }
        delete newObj.Created;
        delete newObj.Households;
        delete newObj.StartUp;
        delete newObj.Updated;
        delete newObj.DealId;
        delete newObj.Active;
        delete newObj.Id;
      });

      return newObj;
    });

    const ws = XLSX.utils.json_to_sheet(mappedArray);
    XLSX.utils.sheet_add_aoa(
      ws,
      [
        [
          "Navn",
          "Eier",
          "Sted",
          "Gatenavn",
          "Gatenummer",
          "Husbokstav",
          "Postnummer",
        ],
      ],
      {
        origin: "A1",
      }
    );
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return (
    <Button
      variant={"outline"}
      colorScheme="orange"
      size={"xs"}
      rightIcon={<AddIcon />}
      mx={1}
      onClick={(e) => exportToCSV(tableData, fileName)}
    >
      Eksporter tabellen
    </Button>
  );
};

export default ExportToExcelContainers;
