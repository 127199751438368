import React, { useState, useRef } from "react";
import styled from "styled-components";
import { colors } from "../../styles/variables";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Badge,
  useToast,
  Tag,
  TagLabel,
  TagRightIcon,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Stack,
  Switch,
  textDecoration,
  HStack,
  Input,
  Checkbox,
  Text,
  Radio,
  RadioGroup,
  FormControl,
} from "@chakra-ui/react";
import axios from "axios";
import { ReactComponent as Edit } from "../../assets/icons/Edit.svg";

const LocationTableElement = ({
  tableValue,
  editValue,
  addZeroToPostalCode,
  locationId,
  getLocations,
}) => {
  const editModal = useDisclosure();
  const [edit, setEdit] = useState(false);
  const [switchValue, setSwitchValue] = useState(tableValue);
  const tableValueInputRef = useRef();
  const token = localStorage.getItem("API_token");

  const translate = (txt) => {
    let translatedAttribute;
    switch (txt) {
      case "Active":
        return (translatedAttribute = "Status");
  }
  };

  let headers = {
    authorization: `Bearer ${token}`,
  };

  const openEditBoxHandler = () => {
    setEdit(!edit);
  };
  const openEditModalHandler = () => {
    editModal.onOpen();
  };

  const changeValueHandler = (val) => {
    let editedField;
    editedField = {
      [val]: switchValue,
    };

    axios
      .put(
        `https://gpshu4lon5.execute-api.eu-north-1.amazonaws.com/Test/units/${locationId}`,
        editedField,
        { headers: headers }
      )
      .then((res) => {
        getLocations();
        editModal.onClose();
      })
      .catch((err) => console.log(err));
  };

  const changeTableValueHandler = () => {
    setSwitchValue(!switchValue);
  };

  return (
    <>
      <TableData
        onMouseEnter={openEditBoxHandler}
        onMouseLeave={openEditBoxHandler}
      >
        <DataContainer onClick={openEditModalHandler}>
          {tableValue ? (
            <Badge colorScheme="green">i drift</Badge>
          ) : (
            <Badge colorScheme="red">ikke i drift</Badge>
          )}
          {edit && <IconEdit />}
        </DataContainer>
      </TableData>

      <Modal
        motionPreset="slideInBottom"
        isCentered
        isOpen={editModal.isOpen}
        onClose={editModal.onClose}
      >
      <ModalOverlay />

        <ModalContent>
          <ModalCloseButton size="sm" />
          <ModalHeader>Rediger {translate(editValue)}</ModalHeader>
          <ModalBody>
            <SwitchWrapper>
              <SwitchEl
                onChange={changeTableValueHandler}
                isChecked={switchValue ? true : false}
                colorScheme="green"
                size="md"
              />

              <SwitchDescription>
                {switchValue ? "i drift" : "ikke i drift"}
              </SwitchDescription>
            </SwitchWrapper>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="teal"
              variant="solid"
              mr={3}
              onClick={() => changeValueHandler(editValue)}
            >
              Lagre
            </Button>
            <Button
              variant="ghost"
              colorScheme="teal"
              mr={3}
              onClick={() => editModal.onClose()}
            >
              Avbryt
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const DataContainer = styled.div``;
const IconEdit = styled(Edit)`
  width: 0.65rem;
  height: 0.65rem;
  cursor: pointer;
  position: absolute;
  left: -13px;
  top: -5px;
  margin: 1rem;
`;
const TableData = styled(Td)`
  position: relative;
  &:hover {
    background-color: ${colors.greyLight};
  }
`;

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const SwitchEl = styled(Switch)`
  margin-right: 1rem;
`;

const SwitchDescription = styled(Text)``;

const ModalHeaderText = styled.h1``;
export default LocationTableElement;
