import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateNodes } from "../../features/NodesSlice";
import { Link, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { colors, fonts } from "../../styles/variables";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Badge,
  useToast,
  Tag,
  TagLabel,
  TagRightIcon,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Stack,
  StackDivider,
  textDecoration,
  HStack,
  VStack,
  Input,
  Checkbox,
  Text,
  Radio,
  RadioGroup,
  FormControl,
  Spinner,
} from "@chakra-ui/react";
import NodeElement from "./NodeElement";
import axios from "axios";
import { ReactComponent as AddGreen } from "../../assets/icons/AddGreen.svg";
import { AddIcon } from "@chakra-ui/icons";
const Nodes = () => {
  const [nodesList, setNodesList] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const token = localStorage.getItem("API_token");
  const dispatch = useDispatch();
  const thingNameInputRef = useRef();
  let headers = {
    authorization: `Bearer ${token}`,
  };
  useEffect(() => {
    getNodes();
  }, []);

  const getNodes = () => {
    axios
      .get(
        `https://gpshu4lon5.execute-api.eu-north-1.amazonaws.com/Test/nodes`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.data) {
          setNodesList(res.data);
          dispatch(updateNodes(res.data));
        }
      })
      .catch((err) => console.log(err));
  };

  let node = {
    ThingName: "",
  };

  const addNodeHandler = () => {
    node = {
      ThingName: thingNameInputRef.current.value,
    };

    axios
      .post(
        `https://gpshu4lon5.execute-api.eu-north-1.amazonaws.com/Test/nodes`,
        JSON.stringify([node]),
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          onClose();
          getNodes();
        }

        console.log(res.data);
      });
  };

  const deleteNodeHandler = (id) => {
    axios
      .delete(
        `https://gpshu4lon5.execute-api.eu-north-1.amazonaws.com/Test/nodes/${id}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        console.log(res);
        getNodes();
      })
      .catch((err) => console.log(err));
  };

  return (
    <Wrapper>
      <Content>
        <Title>Lås</Title>
        <BreakingLine />
        <Button
          rightIcon={<AddIcon />}
          colorScheme="teal"
          size={"xs"}
          variant={"outline"}
          onClick={onOpen}
        >
          Registrer ny lås
        </Button>

        <Modal
          isCentered
          closeOnOverlayClick={false}
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Ny lås</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing={1}>
                <Text fontSize="xs">Navn</Text>
                <Input
                  focusBorderColor="teal.400"
                  variant="filled"
                  ref={thingNameInputRef}
                />
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Button onClick={addNodeHandler} mr={3} colorScheme="teal">
                Lagre
              </Button>
              <Button
                variant="ghost"
                colorScheme="teal"
                mr={3}
                onClick={onClose}
              >
                Avbryt
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <TableMainContainer>
          <Table variant="simple" size="sm" colorScheme={"teal"}>
            <Thead>
              <Tr>
                <Th>
                  <TableHeader>Navn</TableHeader>
                </Th>
                <Th>
                  <TableHeader>Versjon</TableHeader>
                </Th>
                <Th>
                  <TableHeader>Batterinivå</TableHeader>
                </Th>
                <Th>
                  <TableHeader>Rsrp</TableHeader>
                </Th>
                <Th>
                  <TableHeader>Siste hendelse</TableHeader>
                </Th>
                <Th>
                  <TableHeader>Siste Batteristatus</TableHeader>
                </Th>
                <Th>
                  <TableHeader>Logg</TableHeader>
                </Th>
                {/* <Th>Slett</Th> */}
              </Tr>
            </Thead>
            <Tbody>
              {nodesList?.map((node, index) => (
                <NodeElement
                  nodeId={node.Id}
                  key={index}
                  name={node.ThingName}
                  version={node.Version}
                  batteryLevel={node.BatteryLevel}
                  signalStrength={node.Rsrp}
                  lastAlive={node.LastAlive}
                  lastStatus={node.LastStatus}
                  deleteNode={deleteNodeHandler}
                  getNodes={getNodes}
                />
              ))}
            </Tbody>
          </Table>
          {!nodesList && (
            <SpinnerWrapper>
              <Spinner size="lg" color={colors.greenMain} />
            </SpinnerWrapper>
          )}
        </TableMainContainer>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 92.5vh;
  display: flex;
  flex: 1;
  overflow: auto;
  background-color: ${colors.greyLight};
`;

const Content = styled.div`
  font-size: 1.25rem;
  padding: 2.5rem;
  margin: 2rem;
  border-radius: 5px;
  font-family: "Roboto";
  background-color: white;
  height: auto;
  overflow: auto;
`;
const Title = styled.h1`
  font-weight: 600;
`;
const BreakingLine = styled.hr`
  border-top: 1px solid lightgrey;
`;

const AddNode = styled(Tag)`
  margin-top: 2rem;
  cursor: pointer;
  border: 2px solid white;
  transition: all 0.2s ease-in-out;
  &:hover {
    border: 2px solid #69b1bf;
  }
`;

const TableMainContainer = styled(TableContainer)`
  margin-top: 3rem;
`;
const TableHeader = styled(Text)`
  font-size: 0.6rem;
  font-weight: bold;
`;
const SpinnerWrapper = styled.div`
  width: 100%;
  height: 4rem;
  padding-top: 1.5rem;
  display: flex;
  justify-content: center;
`;
export default Nodes;
