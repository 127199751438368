import React from "react";
import { Button } from "@chakra-ui/react";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { ArrowUpIcon } from "@chakra-ui/icons";
import {
  ExportUserType,
  TagUserType,
  TagUsersType,
} from "../../validators/User";

const exportToCSV = (tableData: TagUsersType, fileName: string) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportData = tableData.map(
    (user: TagUserType): ExportUserType => ({
      Navn: user.Name,
      Epost: user.Email,
      Brikke: user.TagId,
      Adresse: `${user?.StreetName ?? ""} ${user?.StreetNumber ?? ""} ${
        user?.Letter ?? ""
      }`,
      Sted: `${user?.PostalCode ?? ""} ${user?.City ?? ""}`,
    })
  );

  const userSheet = XLSX.utils.json_to_sheet(exportData);
  const workBook = { Sheets: { data: userSheet }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  saveAs(data, fileName + fileExtension);
};

const ExportToExcelTag = ({ fileName, tableData }: any) => {
  return (
    <Button
      size={"xs"}
      colorScheme="orange"
      variant={"outline"}
      onClick={() => exportToCSV(tableData, fileName)}
      rightIcon={<ArrowUpIcon />}
    >
      Eksporter tabellen
    </Button>
  );
};

export default ExportToExcelTag;
