import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../styles/variables";
import { ReactComponent as Delete } from "../../assets/icons/Delete.svg";
import { ReactComponent as Details } from "../../assets/icons/Details.svg";

import {
  Tr,
  Td,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import LocationTableElementText from "./LocationTableElementText";
import LocationnTableElementBoolean from "./LocationTableElementBoolean";
import { addZeroToPostalCode } from "../../utilities/FormatPostalCode";
const Location = ({
  rangeKey,
  id,
  url,
  name,
  owner,
  city,
  postalCode,
  streetName,
  streetNumber,
  houseLetter,
  active,
  deleteUnit,
  getLocations,
}) => {
  const deleteModal = useDisclosure();

  const confirmDeletingUnitHandler = () => {
    deleteUnit(id, rangeKey);
    deleteModal.onClose();
  };

  useEffect(() => {
    console.log("re-render location");
  }, []);

  return (
    <>
      <Tr>
        <TableData>
          <Link exact="true" to={`${url}/${id}`}>
            <IconDetails />
          </Link>
        </TableData>
        <LocationTableElementText
          editValue="Name"
          locationId={id}
          tableValue={name}
          getLocations={getLocations}
        />
        <LocationTableElementText
          editValue="Owner"
          locationId={id}
          tableValue={owner}
          getLocations={getLocations}
        />
        <LocationTableElementText
          editValue="City"
          locationId={id}
          tableValue={city}
          getLocations={getLocations}
        />
        <LocationTableElementText
          editValue="PostalCode"
          tableValue={postalCode}
          locationId={id}
          addZeroToPostalCode={addZeroToPostalCode}
          getLocations={getLocations}
        />
        <LocationTableElementText
          editValue="StreetName"
          locationId={id}
          tableValue={streetName}
          getLocations={getLocations}
        />
        <LocationTableElementText
          editValue="StreetNumber"
          locationId={id}
          tableValue={streetNumber}
          getLocations={getLocations}
        />
        <LocationTableElementText
          editValue="Letter"
          locationId={id}
          tableValue={houseLetter}
          getLocations={getLocations}
        />
        <LocationnTableElementBoolean
          tableValue={active}
          locationId={id}
          editValue="Active"
          getLocations={getLocations}
        />
        <TableData onClick={() => deleteModal.onOpen()}>
          <IconDelete title="slett" onClick={() => deleteModal.onOpen()} />
        </TableData>
      </Tr>
      <Modal
        motionPreset="slideInBottom"
        isCentered
        isOpen={deleteModal.isOpen}
        onClose={deleteModal.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Er du sikker at du vil slette denne lokasjonen?
          </ModalHeader>
          <ModalCloseButton />
          <ModalFooter>
            <Button
              colorScheme="teal"
              variant="solid"
              mr={3}
              onClick={() => confirmDeletingUnitHandler()}
            >
              Ja
            </Button>
            <Button
              variant="ghost"
              colorScheme="teal"
              mr={3}
              onClick={() => deleteModal.onClose()}
            >
              Nei
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const IconDelete = styled(Delete)`
  width: 0.65rem;
  height: 0.65rem;
  cursor: pointer;
`;

const IconDetails = styled(Details)`
  width: 0.85rem;
  height: 0.85rem;
  cursor: pointer;
`;

const TableData = styled(Td)`
  position: relative;
  &:hover {
    background-color: ${colors.greyLight};
  }
`;

const DataContainer = styled.div``;

export default Location;
