import React from "react";
import { Button } from "@chakra-ui/react";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { ContainersType } from "../../validators/Container";
import { ArrowUpIcon } from "@chakra-ui/icons";

const exportToCSV = (tableData: any, fileName: string) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const ws = XLSX.utils.json_to_sheet(tableData);
  const workbook = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });
  const data = new Blob([excelBuffer], { type: fileType });
  saveAs(data, fileName + fileExtension);
};

interface ExportToExcelContainersProps {
  fileName: string;
  tableData: ContainersType;
}

const ExportToExcelContainers = ({
  fileName,
  tableData,
}: ExportToExcelContainersProps) => {
  return (
    <Button
      rightIcon={<ArrowUpIcon />}
      colorScheme="orange"
      size={"xs"}
      variant={"outline"}
      onClick={(e) => exportToCSV(tableData, fileName)}
    >
      Eksporter tabellen
    </Button>
  );
};

export default ExportToExcelContainers;
