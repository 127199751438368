import React, { useState, useRef } from "react";
import styled from "styled-components";
import { colors } from "../../styles/variables";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Badge,
  useToast,
  Tag,
  TagLabel,
  TagRightIcon,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Stack,
  textDecoration,
  HStack,
  Input,
  Checkbox,
  Text,
  Radio,
  RadioGroup,
  FormControl,
} from "@chakra-ui/react";
import axios from "axios";
import { ReactComponent as Edit } from "../../assets/icons/Edit.svg";

const LocationTableElementText = ({
  tableValue,
  editValue,
  addZeroToPostalCode,
  householdId,
  getHouseholds,
}) => {
  const editModal = useDisclosure();
  const [edit, setEdit] = useState(false);
  const tableValueInputRef = useRef();
  const token = localStorage.getItem("API_token");
  let headers = {
    authorization: `Bearer ${token}`,
  };
  const translate = (txt) => {
    let translatedAttribute;
    switch (txt) {
      case "Name":
        return (translatedAttribute = "Navn");
      case "Owner":
        return (translatedAttribute = "Eier");
      case "City":
        return (translatedAttribute = "Sted");
      case "PostalCode":
        return (translatedAttribute = "Postnummer");
      case "StreetName":
        return (translatedAttribute = "Gatenavn");
      case "StreetNumber":
        return (translatedAttribute = "Gatenummer");
      case "Letter":
        return (translatedAttribute = "Bokstav");
      case "Apartment":
        return (translatedAttribute = "Leilighetsnummer");
      case "DealId":
        return (translatedAttribute = "Avtale-Id");
      case "SectionId":
        return (translatedAttribute = "Seksjons-Id");
      case "CollectionId":
        return (translatedAttribute = "Innsamlings-Id");
      case "BusinessType":
        return (translatedAttribute = "Business-Type");
      case "UnitNames":
        return (translatedAttribute = "Lokasjon");
    }
  };

  const openEditBoxHandler = (e) => {
    setEdit(!edit);
  };
  const openEditModalHandler = () => {
    editModal.onOpen();
  };

  const changeTextHandler = (val) => {
    let editedField;
    if (val.match(/\d/)) {
      editedField = {
        [val]: Number(tableValueInputRef.current.value),
      };
    } else {
      editedField = {
        [val]: tableValueInputRef.current.value,
      };
    }

    console.log(editedField);
    axios
      .put(
        `https://gpshu4lon5.execute-api.eu-north-1.amazonaws.com/Test/households/${householdId}`,
        editedField,
        { headers: headers }
      )
      .then((res) => {
        if (typeof tableValueInputRef.current.value === "string") {
          tableValueInputRef.current.value = "";
        }
        editModal.onClose();
        getHouseholds();
      })
      .catch((err) => console.log(err));
  };
  const placeholderValue = `Ny ${translate(editValue)
    .split(" ")[0]
    .toLowerCase()}`;
  return (
    <>
      <TableData
        onMouseEnter={openEditBoxHandler}
        onMouseLeave={openEditBoxHandler}
      >
        <DataContainer onClick={openEditModalHandler}>
          {editValue === "PostalCode" ? addZeroToPostalCode(tableValue) : tableValue || <Badge>N/A</Badge>}
          {edit && <IconEdit />}
        </DataContainer>
      </TableData>

      <Modal
        motionPreset="slideInBottom"
        isCentered
        isOpen={editModal.isOpen}
        onClose={editModal.onClose}
      >
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton size="sm" />
          <ModalHeader>Rediger {translate(editValue)}</ModalHeader>
          <ModalBody>
            <Stack spacing={1}>
              <Input
                ref={tableValueInputRef}
                placeholder={placeholderValue}
                focusBorderColor="teal.400"
                variant="filled"
              />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="teal"
              variant="solid"
              mr={3}
              onClick={() => changeTextHandler(editValue)}
            >
              Lagre
            </Button>
            <Button
              variant="ghost"
              colorScheme="teal"
              mr={3}
              onClick={() => editModal.onClose()}
            >
              Avbryt
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const DataContainer = styled.div``;
const IconEdit = styled(Edit)`
  width: 0.65rem;
  height: 0.65rem;
  cursor: pointer;
  position: absolute;
  left: -12px;
  top: -5px;
  margin: 1rem;
`;
const TableData = styled(Td)`
  position: relative;
  &:hover {
    background-color: ${colors.greyLight};
  }
`;

const ModalHeaderText = styled.h1``;
export default LocationTableElementText;
