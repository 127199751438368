import React, { useState, useRef, useEffect } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { colors, fonts } from "../../styles/variables";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Badge,
  useToast,
  Tag,
  TagLabel,
  TagRightIcon,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Stack,
  StackDivider,
  textDecoration,
  HStack,
  VStack,
  Input,
  Checkbox,
  Text,
  Radio,
  RadioGroup,
  FormControl,
  Spinner,
} from "@chakra-ui/react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { custom_date } from "../../utilities/customDate";
import { ReactComponent as ExportOrange } from "../../assets/icons/ExportOrange.svg";
import { ArrowUpIcon } from "@chakra-ui/icons";

const ExportToExcelTags = ({ fileName, tableData }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (tableData, fileName) => {
    const copiedTableData = [...tableData];
    const mappedArray = copiedTableData.map((obj) => {
      const newObj = { ...obj };

      Object.keys(newObj).forEach((key) => {
        if (newObj[key] === null) {
          newObj[key] = "N/A";
        }
      });

      return newObj;
    });

    console.log(mappedArray);

    const ws = XLSX.utils.json_to_sheet(mappedArray);
    XLSX.utils.sheet_add_aoa(
      ws,
      [["Internal-Id", "Ekternal-Id", "Eier", "Opprettet"]],
      {
        origin: "A1",
      }
    );
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return (
    <Button
      variant={"outline"}
      colorScheme="orange"
      size={"xs"}
      rightIcon={<ArrowUpIcon />}
      mx={1}
      onClick={(e) => exportToCSV(tableData, fileName)}
    >
      Eksporter tabellen
    </Button>
  );
};

export default ExportToExcelTags;
