import React, { useState, useRef, useEffect } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { colors, fonts } from "../../styles/variables";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Badge,
  useToast,
  Tag,
  TagLabel,
  TagRightIcon,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Stack,
  StackDivider,
  textDecoration,
  HStack,
  VStack,
  Input,
  Checkbox,
  Text,
  Radio,
  RadioGroup,
  FormControl,
  Spinner,
} from "@chakra-ui/react";
import Household from "./Household";
import ExportToExcelHouseholds from "./ExportToExcelHouseholds";
import axios from "axios";
import * as XLSX from "xlsx";
import { ReactComponent as AddGreen } from "../../assets/icons/AddGreen.svg";
import { ReactComponent as AddBlue } from "../../assets/icons/AddBlue.svg";
import { ReactComponent as ExportOrange } from "../../assets/icons/ExportOrange.svg";
import { ReactComponent as Sorting } from "../../assets/icons/Sorting.svg";
import { AddIcon } from "@chakra-ui/icons";

const HouseholdsList = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [householdsList, setHouseholdsList] = useState();
  const [order, setOrder] = useState("ASC");

  const token = localStorage.getItem("API_token");
  const fileName = "Boenheter";

  const CityInputRef = useRef();
  const PostalCodeInputRef = useRef();
  const StreetNameInputRef = useRef();
  const StreetNumberInputRef = useRef();
  const LetterInputRef = useRef();
  const ApartmentInputRef = useRef();
  const DealIdInputRef = useRef();
  const SectionIdInputRef = useRef();
  const CollectionIdInputRef = useRef();
  const BusinessTypeInputRef = useRef();

  let headers = {
    authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    getHouseholds();
  }, []);

  const getHouseholds = () => {
    axios
      .get(
        `https://gpshu4lon5.execute-api.eu-north-1.amazonaws.com/Test/households`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.data) {
          setHouseholdsList(res.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const deleteHousholdHandler = (id) => {
    axios
      .delete(
        `https://gpshu4lon5.execute-api.eu-north-1.amazonaws.com/Test/households/${id}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setHouseholdsList(
            householdsList.filter((household) => household.Id !== id)
          );
        }
      });
  };

  const createHouseholdHandler = () => {
    let household = {
      City: "",
      PostalCode: null,
      StreetName: "",
      StreetNumber: null,
      Letter: "",
      Apartment: "",
      DealId: "",
      SectionId: "",
      CollectionId: "",
      BusinessType: "",
      UnitId: "",
    };
    household = {
      City: CityInputRef.current.value,
      PostalCode: PostalCodeInputRef.current.value,
      StreetName: StreetNameInputRef.current.value,
      StreetNumber: StreetNumberInputRef.current.value,
      Letter: LetterInputRef.current.value,
      Apartment: ApartmentInputRef.current.value,
      DealId: DealIdInputRef.current.value,
      SectionId: SectionIdInputRef.current.value,
      CollectionId: CollectionIdInputRef.current.value,
      BusinessType: BusinessTypeInputRef.current.value,
    };
    axios
      .post(
        `https://gpshu4lon5.execute-api.eu-north-1.amazonaws.com/Test/households`,
        [household],
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          getHouseholds();
          onClose();
        }
      });
  };

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, { defval: "" });
        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      console.log(d);
      d.forEach((obj, idx) => {
        let keysArr = [
          "City",
          "PostalCode",
          "StreetName",
          "StreetNumber",
          "Letter",
          "Apartment",
          "DealId",
          "SectionId",
          "CollectionId",
          "BusinessType",
        ];
        let keys = Object.keys(obj);
        keys.forEach((key, index) => {
          let value = obj[key];
          delete obj[key];
          obj[keysArr[index]] = value;
        });
      });

      console.log(d);
      axios
        .post(
          `https://gpshu4lon5.execute-api.eu-north-1.amazonaws.com/Test/households`,
          d,
          {
            headers: headers,
          }
        )

        .then((res) => {
          if (res.status === 200) {
            getHouseholds();
          }
        });
    });
  };

  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...householdsList].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setHouseholdsList(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...householdsList].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setHouseholdsList(sorted);
      setOrder("ASC");
    }
  };

  return (
    <Wrapper>
      <Content>
        <Title>Boenheter</Title>
        <BreakingLine />
        <ControlTableButtonsContainer>
          {/* TODO: Add functionality */}
          {/* <Button
            size="xs"
            variant={"outline"}
            colorScheme="teal"
            onClick={onOpen}
            rightIcon={<AddIcon />}
          >
            Ny boenhet
          </Button> */}

          {/* TODO: Add functionality */}
          {/* <Button
            variant={"outline"}
            colorScheme="blue"
            size={"xs"}
            rightIcon={<AddIcon />}
            mx={1}
            onChange={(e) => {
              const file = e.target.files[0];
              readExcel(file);
            }}
          >
            <label style={{ cursor: "pointer" }}>
              <Input type="file" display="none" />
              Importer fra fil
            </label>
          </Button> */}

          <ExportToExcelHouseholds
            fileName={fileName}
            tableData={householdsList}
          />
        </ControlTableButtonsContainer>

        <TableMainContainer>
          <Table variant="simple" size="sm" colorScheme={"teal"}>
            <Thead>
              <Tr>
                <Th>
                  <TableHeader>Info</TableHeader>
                </Th>
                <Th>
                  <ThWrapper>
                    <TableHeader>Sted</TableHeader>
                    <SortingIcon onClick={() => sorting("City")} />
                  </ThWrapper>
                </Th>
                <Th>
                  <ThWrapper>
                    <TableHeader>Postnummer</TableHeader>
                    <SortingIcon onClick={() => sorting("PostalCode")} />
                  </ThWrapper>
                </Th>
                <Th>
                  <ThWrapper>
                    <TableHeader>Gatenavn</TableHeader>
                    <SortingIcon onClick={() => sorting("StreetName")} />
                  </ThWrapper>
                </Th>
                <Th>
                  <ThWrapper>
                    <TableHeader>Gatenummer</TableHeader>
                    <SortingIcon onClick={() => sorting("StreetNumber")} />
                  </ThWrapper>
                </Th>
                <Th>
                  <ThWrapper>
                    <TableHeader>Bokstav</TableHeader>
                    <SortingIcon onClick={() => sorting("Letter")} />
                  </ThWrapper>
                </Th>
                <Th>
                  <ThWrapper>
                    <TableHeader>Leilighetsnummer</TableHeader>
                    <SortingIcon onClick={() => sorting("Apartment")} />
                  </ThWrapper>
                </Th>
                <Th>
                  <ThWrapper>
                    <TableHeader>Avtale-id</TableHeader>
                    <SortingIcon onClick={() => sorting("DealId")} />
                  </ThWrapper>
                </Th>
                <Th>
                  <ThWrapper>
                    <TableHeader>Seksjons-id</TableHeader>
                    <SortingIcon onClick={() => sorting("SectionId")} />
                  </ThWrapper>
                </Th>
                <Th>
                  <ThWrapper>
                    <TableHeader>Innsamlings-id</TableHeader>
                    <SortingIcon onClick={() => sorting("CollectionId")} />
                  </ThWrapper>
                </Th>
                <Th>
                  <ThWrapper>
                    <TableHeader>Business-type</TableHeader>
                    <SortingIcon onClick={() => sorting("BusinessType")} />
                  </ThWrapper>
                </Th>
                <Th>
                  <ThWrapper>
                    <TableHeader>Lokasjon</TableHeader>
                    <SortingIcon onClick={() => sorting("UnitName")} />
                  </ThWrapper>
                </Th>
                <Th>
                  <TableHeader>Slett</TableHeader>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {householdsList?.map((household, index) => (
                <Household
                  id={household.Id}
                  key={index}
                  city={household.City}
                  postalCode={household.PostalCode}
                  streetName={household.StreetName}
                  streetNumber={household.StreetNumber}
                  letter={household.Letter}
                  apartment={household.Apartment}
                  dealId={household.DealId}
                  sectionId={household.SectionId}
                  collectionId={household.CollectionId}
                  businessType={household.BusinessType}
                  location={household.UnitNames}
                  locationId={household.Units}
                  deleteHousholdHandler={deleteHousholdHandler}
                  units={household.Units}
                  getHouseholds={getHouseholds}
                />
              ))}
            </Tbody>
          </Table>
          {!householdsList && (
            <SpinnerWrapper>
              <Spinner size="lg" color={colors.greenMain} />
            </SpinnerWrapper>
          )}
        </TableMainContainer>
        <Modal
          isCentered
          closeOnOverlayClick={false}
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Ny boenhet</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack
                spacing={4}
                divider={<StackDivider borderColor="gray.600" />}
                direction={"column"}
              >
                <Stack spacing={3}>
                  <Stack spacing={1}>
                    <Text fontSize="xs">Sted</Text>
                    <Input
                      focusBorderColor="teal.400"
                      variant="filled"
                      ref={CityInputRef}
                    />
                  </Stack>
                  <Stack spacing={1}>
                    <Text fontSize="xs">Postnummer</Text>
                    <Input
                      type="number"
                      focusBorderColor="teal.400"
                      variant="filled"
                      ref={PostalCodeInputRef}
                    />
                  </Stack>
                  <Stack spacing={1}>
                    <Text fontSize="xs">Gatenavn</Text>
                    <Input
                      focusBorderColor="teal.400"
                      variant="filled"
                      ref={StreetNameInputRef}
                    />
                  </Stack>

                  <HStack>
                    <Stack spacing={1}>
                      <Text fontSize="xs">Gatenummer</Text>
                      <Input
                        type="number"
                        focusBorderColor="teal.400"
                        variant="filled"
                        htmlSize={32}
                        width="auto"
                        ref={StreetNumberInputRef}
                      />
                    </Stack>
                    <Stack spacing={1}>
                      <Text fontSize="xs">Bokstav</Text>
                      <Input
                        variant="filled"
                        htmlSize={4}
                        width="auto"
                        ref={LetterInputRef}
                      />
                    </Stack>
                  </HStack>
                  <Stack spacing={1}>
                    <Text fontSize="xs">Leilighetsnummer</Text>
                    <Input
                      variant="filled"
                      htmlSize={4}
                      width="auto"
                      ref={ApartmentInputRef}
                    />
                  </Stack>
                  <Stack spacing={1}>
                    <Text fontSize="xs">Avtale-id</Text>
                    <Input
                      variant="filled"
                      htmlSize={4}
                      width="auto"
                      ref={DealIdInputRef}
                    />
                  </Stack>
                  <Stack spacing={1}>
                    <Text fontSize="xs">Seksjons-id</Text>
                    <Input
                      variant="filled"
                      htmlSize={4}
                      width="auto"
                      ref={SectionIdInputRef}
                    />
                  </Stack>
                  <Stack spacing={1}>
                    <Text fontSize="xs">Innsamlings-id</Text>
                    <Input
                      variant="filled"
                      htmlSize={4}
                      width="auto"
                      ref={CollectionIdInputRef}
                    />
                  </Stack>
                  <Stack spacing={1}>
                    <Text fontSize="xs">Business-type</Text>
                    <Input
                      variant="filled"
                      htmlSize={4}
                      width="auto"
                      ref={BusinessTypeInputRef}
                    />
                  </Stack>
                  <Stack spacing={4}></Stack>
                </Stack>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={createHouseholdHandler}
                mr={3}
                colorScheme="teal"
              >
                Lagre
              </Button>
              <Button
                variant="ghost"
                colorScheme="teal"
                mr={3}
                onClick={onClose}
              >
                Avbryt
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 92.5vh;
  display: flex;
  flex: 1;
  overflow: auto;
  background-color: ${colors.greyLight};
`;

const Content = styled.div`
  font-size: 1.25rem;
  padding: 2.5rem;
  margin: 2rem;
  border-radius: 5px;
  font-family: "Roboto";
  background-color: white;
  height: auto;
  overflow: auto;
`;
const Title = styled.h1`
  font-weight: 600;
`;
const BreakingLine = styled.hr`
  border-top: 1px solid lightgrey;
`;
const ControlTableButtonsContainer = styled.div`
  margin-top: 1rem;
  width: 30rem;
  display: flex;
  align-items: center;
`;

const AddHousehold = styled(Tag)`
  cursor: pointer;
  border: 2px solid white;
  width: auto;
  transition: all 0.2s ease-in-out;
  &:hover {
    border: 2px solid #69b1bf;
  }
`;
const UpoloadFile = styled.div`
  width: auto;
  font-size: 1rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const UpoloadFileInputLabel = styled.label`
  width: auto;
  height: 1.5rem;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.greyMedium};
  font-family: ${fonts.mainFont};
  font-size: 0.9rem;
  text-align: center;
  font-weight: 400;
  cursor: pointer;
  border-radius: 5px;
  border: 2px solid white;
  transition: 0.2s border ease-in-out;
  &:hover {
    border: 2px solid ${colors.blue};
  }
`;

const InputUpload = styled.input`
  display: none;
`;

const ExportTable = styled(Tag)`
  cursor: pointer;
  border: 2px solid white;
  width: auto;
  transition: all 0.2s ease-in-out;
  &:hover {
    border: 2px solid ${colors.orange};
  }
`;
const TableMainContainer = styled(TableContainer)`
  margin-top: 3rem;
`;
const ThWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const TableHeader = styled(Text)`
  font-size: 0.6rem;
  font-weight: bold;
`;
const SortingIcon = styled(Sorting)`
  width: 1rem;
  height: 1rem;
  margin-left: 0.75rem;
  cursor: pointer;
`;
const SpinnerWrapper = styled.div`
  width: 100%;
  height: 4rem;
  padding-top: 1.5rem;
  display: flex;
  justify-content: center;
`;

export default HouseholdsList;
