import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { colors } from "../../styles/variables";
import { ReactComponent as Delete } from "../../assets/icons/Delete.svg";
import {
  Tr,
  Td,
  Badge,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  IconButton,
  ModalBody,
  Box,
  Text,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import ContainerTableElementText from "./ContainerTableElementText";
import ContainerTableElementDropdown from "./ContainerTableElementDropdown";
import ContainerTableElementBoolean from "./ContainerTableElementBoolean";
import { ContainerType } from "../../validators/Container";
import ScheduleModal from "./ScheduleModal";
import { Flex } from "@aws-amplify/ui-react";
import { useDeleteContainerMutation } from "../../features/api/ApiSlice";
import formatDate from "../../utilities/formatDate";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import("../../validators/Container");

interface ContainerProps {
  container: ContainerType;
}

const ContainerRow = ({ container }: ContainerProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const scheduleModal = useDisclosure();
  const toast = useToast();
  const history = useHistory();
  const [deleteContainer, { isLoading, isSuccess }] =
    useDeleteContainerMutation();
  const [displayScheduleModal, setDisplayScheduleModal] = useState(false);

  // Handle actions on create success
  useEffect(() => {
    if (isSuccess) {
      onClose();
      toast({
        title: "Container slettet",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [isSuccess, onClose, toast]);

  return (
    <>
      <Tr>
        <ContainerTableElementText
          value={container.Name}
          valueName="Name"
          valueTitle="Navn"
          containerId={container.Id}
        />

        <Td
          _hover={{
            bgColor: `${colors.greyLight}`,
          }}
        >
          {container.UnitName}
        </Td>

        <ContainerTableElementText
          value={container.Fraction}
          valueName="Fraction"
          valueTitle="Fraksjon"
          containerId={container.Id}
        />
        <ContainerTableElementText
          value={container.Type}
          valueName="Type"
          valueTitle="Innkasttype"
          containerId={container.Id}
        />
        <Td
          _hover={{
            bgColor: `${colors.greyLight}`,
          }}
        >
          {formatDate(container.LastUse)}
        </Td>
        <Td
          _hover={{
            bgColor: `${colors.greyLight}`,
          }}
        >
          {formatDate(container.LastFail)}
        </Td>
        <Td
          _hover={{
            bgColor: `${colors.greyLight}`,
          }}
        >
          {formatDate(container.StartUp)}
        </Td>
        <ContainerTableElementText
          value={container.Size}
          valueName="Size"
          valueTitle="Størrelse"
          containerId={container.Id}
        />
        <ContainerTableElementText
          value={container.Coordinates}
          valueName="Coordinates"
          valueTitle="Koordinater"
          containerId={container.Id}
        />
        <ContainerTableElementBoolean
          value={container.Active}
          valueName="Active"
          valueTitle="Driftstatus"
          containerId={container.Id}
        />
        {/* <Td  _hover={{
            borderBottom: `3px solid ${colors.greenLight}`,
            bgColor: `${colors.greyLight}`,
          }} >
          {active ? (
            <Badge colorScheme="green">I drift</Badge>
          ) : (
            <Badge colorScheme="red">ikke i drift</Badge>
          )}
        </Td> */}
        <Td
          _hover={{
            borderBottom: `3px solid ${colors.greenLight}`,
            bgColor: `${colors.greyLight}`,
          }}
        >
          {container.Operative ? (
            <Badge colorScheme="green">fungerer</Badge>
          ) : (
            <Badge colorScheme="red">fungerer ikke</Badge>
          )}
        </Td>
        <ContainerTableElementDropdown
          containerId={container.Id}
          getContainers={() => {}}
          tableValue={container.Node}
          editValue="Node"
        />
        <Td>
          {container.Node ? (
            <Button
              size={"xs"}
              variant={"outline"}
              color={"teal"}
              onClick={() => {
                setDisplayScheduleModal(true);
                scheduleModal.onOpen();
              }}
            >
              Tilpass
            </Button>
          ) : (
            <Tooltip
              label="Ingen lås er tilknyttet denne containeren"
              bg={"gray"}
              rounded={"md"}
              openDelay={500}
              shouldWrapChildren
            >
              <Button isDisabled size={"xs"} variant={"outline"} color={"gray"}>
                Tilpass
              </Button>
            </Tooltip>
          )}
        </Td>
        <Td>
          <Button
            size={"xs"}
            variant={"outline"}
            color={"teal"}
            onClick={() => {
              history.push(`/containere/${container.Id}`);
            }}
          >
            Åpne
          </Button>
        </Td>
        <Td display={"flex"} justifyContent={"center"}>
          <IconButton
            variant="outline"
            colorScheme="gray"
            aria-label="Delete container"
            fontSize="10px"
            size={"xs"}
            icon={<Delete style={{ width: "0.65rem" }} onClick={onOpen} />}
          />
        </Td>
      </Tr>
      <Modal
        motionPreset="slideInBottom"
        isCentered
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Er du sikker at du vil slette denne containeren?
          </ModalHeader>
          <ModalBody>
            <Flex>
              <Box minW={"70px"}>
                <Text fontWeight={"semibold"}>Navn:</Text>
              </Box>
              <Box>
                <Text>{container.Name}</Text>
              </Box>
            </Flex>
            <Flex>
              <Box minW={"70px"}>
                <Text fontWeight={"semibold"}>Lokasjon:</Text>
              </Box>
              <Box>
                <Text>{container.UnitName ?? "Ukjent"}</Text>
              </Box>
            </Flex>
          </ModalBody>

          <ModalCloseButton />
          <ModalFooter>
            <Button
              isLoading={isLoading}
              colorScheme="red"
              variant="outline"
              mr={3}
              onClick={() =>
                deleteContainer({
                  id: container.Id,
                  Created: container.Created,
                })
              }
            >
              Slett
            </Button>
            <Button variant="ghost" colorScheme="teal" mr={3} onClick={onClose}>
              Avbryt
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {displayScheduleModal ? (
        <ScheduleModal
          isOpen={scheduleModal.isOpen}
          onClose={scheduleModal.onClose}
          containerId={container.Id}
          setDisplayModal={setDisplayScheduleModal}
        />
      ) : null}
    </>
  );
};

export default ContainerRow;
