import { z } from "zod";
import { Container } from "./Container";
import { Tag } from "./Tag";

const Household = z.object({
  ContainerData: z.array(Container).nullable(),
  TagData: z.array(Tag),
  Units: z.string().nullable(),
  UnitNames: z.string().nullable(),
  Email: z.string().nullable(),
  Tags: z.string().nullable(),
  Containers: z.string().nullable(),
  City: z.string().nullable(),
  PostalCode: z.number().nullable(),
  StreetName: z.string().nullable(),
  StreetNumber: z.number().nullable(),
  Letter: z.string().nullable(),
  Apartment: z.string().nullable(),
  DealId: z.string().nullable(),
  SectionId: z.string().nullable(),
  CollectionId: z.string().nullable(),
  BusinessType: z.string().nullable(),
  Id: z.string().nullable(),
  Created: z.number().nullable(),
});

const Households = z.array(Household);

export { Household, Households };
export type HouseholdType = z.infer<typeof Household>;
export type HouseholdsType = z.infer<typeof Households>;
