import React from "react";
import { colors } from "../../styles/variables";
import Admin from "./Admin";
import { Stack, Spinner, Text, Flex, Box, Heading } from "@chakra-ui/react";
import { useGetAdminsQuery } from "../../features/api/ApiSlice";
import { AdminType } from "../../validators/Admin";
const Admins = (): JSX.Element => {
  const { data, isError, isLoading } = useGetAdminsQuery();

  return (
    <Flex
      height={"92.5vh"}
      flex={"1"}
      overflow={"auto"}
      bgColor={`${colors.greyLight}`}
    >
      <Box
        fontSize={"1.25rem"}
        p={"2.5rem"}
        m={"2rem"}
        borderRadius={"5px"}
        fontFamily={"Roboto"}
        bgColor={"white"}
        height={"auto"}
        overflow={"auto"}
      >
        <Heading as={"h2"} size={"md"} fontFamily={"Roboto"} fontWeight={600}>
          Administratorer
        </Heading>
        <hr style={{ borderTop: "1px solid lightgrey" }} />
        {isError ? (
          <Text>Oops! Noe har gått galt. Venligst forsøk igjen senere.</Text>
        ) : isLoading ? (
          <Flex
            width={"100%"}
            height={"4rem"}
            pt={"1.5rem"}
            justifyContent={"center"}
          >
            <Spinner size="lg" color={colors.greenMain} />
          </Flex>
        ) : (
          // TODO: Handle undefined data from enpoint?
          <Stack spacing={2} mt={"1.25rem"}>
            {data?.map((admin: AdminType, index: number) => (
              <Admin key={index} admin={admin} />
            ))}
          </Stack>
        )}
      </Box>
    </Flex>
  );
};

export default Admins;
