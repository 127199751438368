export const colors = {
  blue: "#173665",
  blue2: "#4870FF",
  blueLight: "#E4EAFF",
  white: "#ffffff",
  greenMain: "#5DBB91",
  greenShiny: "#B2F5EA",
  greenLight: "#78C2A5",
  greenUltraLight: "#F2FFFA",
  greenPaleLight: "#EBFFF7",
  greyLight: "#C4C4C425",
  greyMedium: "#CBCBCB",
  greenDark: "#8DA89B",
  grey: "#131313",
  greyMedium: "#EDF2F7",
  orange: "#C86026",
};

export const fonts = {
  mainFont: "Roboto",
};
