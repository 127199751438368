import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "./aws-exports";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import styled from "styled-components";
import { colors } from "./styles/variables";
import PortalRoutes from "./navigation/PortalRoutes";
import MainPage from "./components/MainPage";
import { Flex } from "@chakra-ui/react";

Amplify.configure(awsExports);

function App() {
  return (
    <Flex
      justifyContent={"center"}
      alignItems={"center"}
      overflow={"auto"}
      boxSizing={"border-box"}
      bgColor={colors.greyLight}
      height={"100vh"}
    >
      <AuthEl>
        {({ signOut, user }) => (
          <Router>
            <MainPage signOut={signOut} user={user}>
              <PortalRoutes signOut={signOut} user={user} />
            </MainPage>
          </Router>
        )}
      </AuthEl>
    </Flex>
  );
}

// TODO: Remove styled component
const AuthEl = styled(Authenticator)`
  button.amplify-button {
    background-color: ${colors.greenMain};
    color: ${colors.blue};
    &:hover {
      background-color: ${colors.greenMain};
      color: ${colors.white};
    }
  }
  ${colors.greenMain} {
    border: 1px solid ${colors.greenMain};
  }
  button.amplify-field__show-password {
    background-color: white;
    &:focus {
      border: 1px solid ${colors.greenMain};
      box-shadow: none;
    }
  }
  .amplify-button[data-variation="link"] {
    background-color: ${colors.greenPaleLight};
  }
  .amplify-input:focus {
    border: 1px solid ${colors.greenMain};
    box-shadow: none;
  }
`;

export default App;
