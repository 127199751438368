const formatDate = (time: number | null) => {
  return time
    ? new Date(time * 1000).toLocaleString("nb-NO", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      })
    : "";
};

export default formatDate;
