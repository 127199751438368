import { z } from "zod";

const Container = z.object({
  Created: z.number().nullable(),
  StartUp: z.number().nullable(),
  Updated: z.number().nullable(),
  Active: z.boolean().nullable(),
  Coordinates: z.string().nullable(),
  FillLevel: z.number().nullable(),
  Id: z.string(),
  Fraction: z.string().nullable(),
  LastFail: z.number().nullable(),
  LastUse: z.number().nullable(),
  Name: z.string().nullable(),
  Open: z.boolean().nullable(),
  Operative: z.boolean().nullable(),
  Size: z.string().nullable(),
  Tags: z.array(z.string()).nullable().or(z.string().nullable()),
  Type: z.string().nullable(),
  UnitId: z.string().nullable(),
  UnitName: z.string().nullable().optional(),
  Node: z.string().nullable(),
});

const Containers = z.array(Container);

// TODO: Make unitID mandatory
const NewContainer = z.object({
  Name: z.string().min(3, "Navnet må inneholde minst 3 tegn"),
  StartUp: z.number().positive("Venligst velg en starttid"),
  // StartUp: z.string().min(5, "Venligst velg en starttid"),
  Node: z.string().nullable(),
  Coordinates: z.string().nullable(),
  Fraction: z.string().nullable(),
  Type: z.string().nullable(),
  Size: z.string().nullable(),
  Active: z.boolean(),
});

const EditContainer = NewContainer.extend({
  StartUp: z.string().min(5, "Venligst velg en starttid"),
});

const LocationContainer = NewContainer.extend({
  UnitId: z.string(),
});

export {
  Container,
  Containers,
  NewContainer,
  LocationContainer,
  EditContainer,
};
export type ContainerType = z.infer<typeof Container>;
export type ContainersType = z.infer<typeof Containers>;
export type NewContainerType = z.infer<typeof NewContainer>;
export type LocationContainerType = z.infer<typeof LocationContainer>;
export type EditContainerType = z.infer<typeof EditContainer>;
