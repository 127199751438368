import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import {
  Button,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Badge,
  Box,
  Switch,
  useToast,
  createStandaloneToast,
} from "@chakra-ui/react";

const LocationInfo = ({ location, statusPutApi }) => {
  const toast = createStandaloneToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
 
  const changeStatusHandler = () => {
    statusPutApi(!location[0].Active);
    onClose();
    toast({
      backgroundColor: "red",
      title: "Status endret",
      description: "Du har nå endret status for loaksjonen",
      status: "success",
      duration: 15000,
      isClosable: true,
      variant: "subtle",
    });
  };
  return (
    <>
      <InfoText>
        <Text as="b">Navn:</Text> {location[0].Name || <Badge>N/A</Badge>}
      </InfoText>
      <InfoText>
        <Text as="b">Eier:</Text> {location[0].Owner || <Badge>N/A</Badge>}
      </InfoText>
      <InfoText>
        <Text as="b">Gatenavn:</Text>{" "}
        {location[0].StreetName || <Badge>N/A</Badge>}
      </InfoText>
      <InfoText>
        <Text as="b">Nummer:</Text>{" "}
        {location[0].StreetNumber || <Badge>N/A</Badge>}
      </InfoText>
      <InfoText>
        <Text as="b">Lokasjon-id:</Text>{" "}
        {(location[0].Id && location[0].Id.slice(0, 8)) || <Badge>N/A</Badge>}
      </InfoText>
      <StatusContainer>
        <InfoText>
          <Text as="b">Status:</Text>
        </InfoText>
        {location[0].Active ? (
          <Badge colorScheme="green">i drift</Badge>
        ) : (
          <Badge colorScheme="red">ikke i drift</Badge>
        )}
      </StatusContainer>
      <SwitchEl
        onChange={() => onOpen()}
        isChecked={location[0].Active ? true : false}
        colorScheme="green"
        size="md"
      >
        Endre status på drift av lokasjon
      </SwitchEl>
      <Modal
        closeOnOverlayClick={false}
        motionPreset="slideInBottom"
        isCentered
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Er du sikker at du vil bytte status?</ModalHeader>
          <ModalCloseButton />
          <ModalFooter>
            <Button
              colorScheme="teal"
              variant="solid"
              mr={3}
              onClick={changeStatusHandler}
            >
              Ja
            </Button>
            <Button variant="ghost" colorScheme="teal" mr={3} onClick={onClose}>
              Nei
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const InfoText = styled(Text)`
  margin-right: 2rem;
  font-size: 1.05rem;
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SwitchEl = styled(Switch)`
  font-size: 1.05rem;
`;
export default LocationInfo;
