import { createSlice } from "@reduxjs/toolkit";

const initialLocationsState = {
  locations: [],
};

export const LocationsSlice = createSlice({
  name: "locations",
  initialState: initialLocationsState,
  reducers: {
    updateLocation: (state, action) => {
      state.locations = action.payload;
    },
    addLocation: (state, action) => {
      state.locations = [...state.locations, action.payload];
    },
  },
});

export const { addLocation, updateLocation } = LocationsSlice.actions;

export default LocationsSlice.reducer;
