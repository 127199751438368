import { configureStore } from "@reduxjs/toolkit";
import LocationsSlice from "../features/LocationsSlice";
import AuthenticationSlice from "../features/AuthenticationSlice";
import NodesSlice from "../features/NodesSlice";
import { Api } from "../features/api/ApiSlice";

export const store = configureStore({
  reducer: {
    [Api.reducerPath]: Api.reducer,
    locationsReducer: LocationsSlice,
    authenticationReducer: AuthenticationSlice,
    nodesReducer: NodesSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(Api.middleware),
});
