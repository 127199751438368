import { z } from "zod";

// Location and Unit is the same entity
const Location = z.object({
  Name: z.string().nullable(),
  Owner: z.string().nullable(),
  DealId: z.string().nullable(),
  City: z.string().nullable(),
  StreetName: z.string().nullable(),
  StreetNumber: z.number().nullable(),
  Letter: z.string().nullable(),
  PostalCode: z.number().nullable(),
  Households: z.string().nullable(),
  Containers: z.string().nullable(),
  Updated: z.number().nullable(),
  Active: z.boolean().nullable(),
  StartUp: z.number().nullable(),
  Id: z.string().nullable(),
  Created: z.number().nullable(),
});

const Locations = z.array(Location);

export { Location, Locations };
export type LocationType = z.infer<typeof Location>;
export type LocationsType = z.infer<typeof Locations>;
