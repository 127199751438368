import React, { useRef } from "react";
import styled from "styled-components";
import { colors } from "../../styles/variables";
import {
  TableContainer,
  Tag,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Stack,
  Input,
  Text,
  Select,
} from "@chakra-ui/react";
import ExportToExcelContainers from "./ExportToExcelContainers";
import {
  useAddContainerMutation,
  useGetContainersQuery,
  useGetNodesQuery,
} from "../../features/api/ApiSlice";
import ContainerTable from "./ContainerTable";

const AllContainers = () => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const fileName = "Containere";

  const NameInputRef = useRef();
  const FractionInputRef = useRef();
  const TypeInputRef = useRef();
  const SizeInputRef = useRef();
  const StartUpInputRef = useRef();
  const CoordinatesInputRef = useRef();
  const NodeSelectRef = useRef();

  const { data } = useGetNodesQuery();
  const containerResponse = useGetContainersQuery();
  const [addContainer] = useAddContainerMutation();

  const optionNodesList = data?.map((node) => (
    <option key={node.Id} value={node.ThingName}>
      {node.ThingName}
    </option>
  ));

  return (
    <Wrapper>
      <Content>
        <Title>Containere</Title>
        <BreakingLine />
        <ControlTableButtonsContainer>
          {/* TODO: FIX */}
          {/* <AddContainer onClick={onOpen} variant="subtle">
            <TagLabel>Legg til en container</TagLabel>
            <TagRightIcon boxsize="12px" as={AddGreen} />
          </AddContainer> */}
          <ExportToExcelContainers
            tableData={containerResponse.data}
            fileName={fileName}
          />
        </ControlTableButtonsContainer>
        <TableMainContainer>
          <ContainerTable
            containerList={containerResponse.data}
            isSuccess={containerResponse.isSuccess}
            isLoading={containerResponse.isLoading}
            noContainersMessage="Ingen containere funnet"
          />
          {/* {!containers && (
            <SpinnerWrapper>
              <Spinner size="lg" color={colors.greenMain} />
            </SpinnerWrapper>
          )} */}
        </TableMainContainer>
        <Modal
          isCentered
          closeOnOverlayClick={false}
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Ny container</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack>
                <Stack spacing={1}>
                  <Text fontSize="xs">Navn</Text>
                  <Input
                    focusBorderColor="teal.400"
                    variant="filled"
                    ref={NameInputRef}
                  />
                </Stack>
                <Stack spacing={1}>
                  <Text fontSize="xs">Fraksjon</Text>
                  <Input
                    focusBorderColor="teal.400"
                    variant="filled"
                    ref={FractionInputRef}
                  />
                </Stack>
                <Stack spacing={1}>
                  <Text fontSize="xs">Innkasttype</Text>
                  <Input
                    focusBorderColor="teal.400"
                    variant="filled"
                    ref={TypeInputRef}
                  />
                </Stack>
                <Stack spacing={1}>
                  <Text fontSize="xs">Størrelse</Text>
                  <Input
                    focusBorderColor="teal.400"
                    variant="filled"
                    ref={SizeInputRef}
                  />
                </Stack>
                <Stack spacing={1}>
                  <Text fontSize="xs">Oppstart av drift</Text>
                  <Input
                    type="date"
                    focusBorderColor="teal.400"
                    variant="filled"
                    ref={StartUpInputRef}
                  />
                </Stack>
                <Stack spacing={1}>
                  <Text fontSize="xs">Koordinater</Text>
                  <Input
                    focusBorderColor="teal.400"
                    variant="filled"
                    ref={CoordinatesInputRef}
                  />
                </Stack>
                <Stack spacing={1}>
                  <Text fontSize="xs">Node</Text>
                  <Select
                    ref={NodeSelectRef}
                    placeholder="velg en node"
                    size="md"
                  >
                    {optionNodesList}
                  </Select>
                </Stack>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={() =>
                  addContainer([
                    {
                      Name: NameInputRef.current.value,
                      Fraction: FractionInputRef.current.value,
                      Type: TypeInputRef.current.value,
                      Size: SizeInputRef.current.value,
                      StartUp:
                        Number(new Date(StartUpInputRef.current.value)) / 1000,
                      Coordinates: CoordinatesInputRef.current.value,
                      Node: NodeSelectRef.current.value,
                    },
                  ])
                }
                colorScheme="teal"
                mr={3}
                variant="solid"
              >
                Lagre
              </Button>
              <Button variant="ghost" mr={3} onClick={onClose}>
                Avbryt
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 92.5vh;
  display: flex;
  flex: 1;
  overflow: auto;
  background-color: ${colors.greyLight};
`;

const Content = styled.div`
  font-size: 1.25rem;
  padding: 2rem;
  margin: 2rem;
  border-radius: 5px;
  font-family: "Roboto";
  background-color: white;
  height: auto;
  overflow: auto;
`;
const Title = styled.h1`
  font-weight: 600;
`;
const BreakingLine = styled.hr`
  border-top: 1px solid lightgrey;
`;
const ControlTableButtonsContainer = styled.div`
  margin-top: 2rem;
  width: 22rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const AddContainer = styled(Tag)`
  cursor: pointer;
  border: 2px solid white;
  transition: all 0.2s ease-in-out;
  &:hover {
    border: 2px solid #69b1bf;
  }
`;
const ExportTable = styled(Tag)`
  cursor: pointer;
  border: 2px solid white;
  width: auto;
  transition: all 0.2s ease-in-out;
  &:hover {
    border: 2px solid ${colors.orange};
  }
`;

const TableMainContainer = styled(TableContainer)`
  margin-top: 3rem;
`;

export default AllContainers;
