import React from "react";
import {
  TableContainer,
  useDisclosure,
  Flex,
  Button,
  Input,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import ExportToExcelContainers from "../Containers/ExportToExcelContainers";
import {
  useGetContainersQuery,
  useGetNodesQuery,
} from "../../features/api/ApiSlice";
import ContainerTable from "../Containers/ContainerTable";
import AddContainerModal from "../Containers/AddContainerModal";

interface LocationContainersProps {
  locationId: string;
}

const LocationContainers = ({ locationId }: LocationContainersProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const containerResponse = useGetContainersQuery(locationId);

  const { data } = useGetNodesQuery();

  return (
    <>
      <Flex mt={"1rem"} w={"30rem"} alignItems={"center"}>
        <Button
          size={"xs"}
          colorScheme={"teal"}
          onClick={onOpen}
          variant="outline"
          rightIcon={<AddIcon />}
          mx={1}
        >
          Ny container
        </Button>

        {/* TODO: Add functionality */}
        {/* <Button
          variant={"outline"}
          colorScheme="blue"
          size={"xs"}
          rightIcon={<AddIcon />}
          mx={1}
        >
          <label style={{ cursor: "pointer" }}>
            <Input type="file" display="none" />
            Importer fra fil
          </label>
        </Button> */}

        <ExportToExcelContainers
          tableData={containerResponse.data ?? []}
          fileName={"Containere/Lokasjon"}
        />
      </Flex>

      <TableContainer mt={"1rem"}>
        <ContainerTable
          containerList={containerResponse.data}
          isLoading={containerResponse.isLoading}
          isSuccess={containerResponse.isLoading}
          noContainersMessage="Det finnes ingen containere på denne lokasjonen"
        />
      </TableContainer>

      <AddContainerModal
        onClose={onClose}
        isOpen={isOpen}
        nodes={data ?? []}
        locationId={locationId}
      />
    </>
  );
};

export default LocationContainers;
