import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
  Checkbox,
  Text,
  CheckboxGroup,
  useToast,
  Center,
  Spinner,
} from "@chakra-ui/react";

import {
  useEditHouseholdMutation,
  useGetContainersQuery,
} from "../../features/api/ApiSlice";
import { ContainersType } from "../../validators/Container";

interface AddContainersToHouseholdsProps {
  locationId: string;
  householdLocations: ContainersType;
  householdId: string;
  onClose: () => void;
  isOpen: boolean;
}

const AddContainersToHouseholds = ({
  locationId,
  householdLocations,
  householdId,
  onClose,
  isOpen,
}: AddContainersToHouseholdsProps) => {
  const [selectedContainers, setSelectedContainers] = useState<string[]>([]);
  const [editHouseholdError, setEditHouseholdError] = useState("");
  const toast = useToast();

  const [
    editHousehold,
    {
      isLoading: isEditHouseHoldLoading,
      isError: isEditHouseholdError,
      isSuccess: isEditHouseholdSuccess,
    },
  ] = useEditHouseholdMutation();
  const { data: containers, isLoading: isContainersLoading } =
    useGetContainersQuery(locationId);

  useEffect(() => {
    if (householdLocations) {
      const containerIds = householdLocations.map((container) => container.Id);
      setSelectedContainers(containerIds);
    }
  }, [householdLocations]);

  const changeSelectedContainers = (containerId: string) => {
    if (selectedContainers.includes(containerId)) {
      const newSelected = selectedContainers.filter(
        (selectedContainerId) => selectedContainerId !== containerId
      );
      setSelectedContainers(newSelected);
      return;
    }
    setSelectedContainers([...selectedContainers, containerId]);
  };

  // Handle error respose from API
  useEffect(() => {
    if (isEditHouseholdError) {
      setEditHouseholdError(
        "Noe har gått galt ved oppdatering av boenheten. Venligst prøv igjen."
      );
      setEditHouseholdError("");
    }
  }, [isEditHouseholdError]);

  // Handle actions on create success
  useEffect(() => {
    if (isEditHouseholdSuccess) {
      onClose();
      toast({
        title: "Container lagt til",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [isEditHouseholdSuccess, onClose, toast]);

  return (
    <>
      <Modal
        isCentered
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Velg containere denne boenheten skal kunne aksessere
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <Stack spacing={1}>
                <Text fontSize="s">Containere</Text>
                {isContainersLoading ? (
                  <Center>
                    <Spinner />
                  </Center>
                ) : (
                  <CheckboxGroup value={selectedContainers}>
                    {containers?.map((container, id) => (
                      <Checkbox
                        key={container.Id}
                        size="md"
                        colorScheme="green"
                        value={container.Id}
                        onChange={() => changeSelectedContainers(container.Id)}
                      >
                        {container.Name} {container.Fraction}
                      </Checkbox>
                    ))}
                  </CheckboxGroup>
                )}
              </Stack>
            </Stack>
            <Text color={"red"}>{editHouseholdError}</Text>
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={isEditHouseHoldLoading}
              onClick={() => {
                editHousehold({
                  householdId: householdId,
                  Containers: selectedContainers.join(),
                });
              }}
              mr={3}
              colorScheme="teal"
            >
              Lagre
            </Button>
            <Button variant="ghost" mr={3} colorScheme="teal" onClick={onClose}>
              Avbryt
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddContainersToHouseholds;
