import React from "react";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Text,
} from "@chakra-ui/react";
import { ScheduleDayType } from "../../validators/Schedule";
import { MODE } from "../../types/modeEnum";

interface ScheduleDayProps {
  scheduleDay: ScheduleDayType;
  day: string;
  changeSchedule: (schedule: ScheduleDayType) => void;
  bgColor: string;
}

const ScheduleDay = ({
  scheduleDay,
  day,
  changeSchedule,
  bgColor,
}: ScheduleDayProps): JSX.Element => {
  const standardMode = scheduleDay.Default ?? MODE.authorized_cards;
  const differingMode = scheduleDay.Mode ?? undefined;
  const startHour = scheduleDay.Start?.slice(0, 2);
  const startMinute = scheduleDay.Start?.slice(2, 4);
  const endHour = scheduleDay.End?.slice(0, 2);
  const endMinute = scheduleDay.End?.slice(2, 4);

  const hourList = [
    "--",
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
  ];

  const isValidTime = (val: string | undefined): boolean => {
    console.log("differingMode", differingMode);
    if (differingMode === null) {
      return false;
    }
    return val ? true : false;
  };

  return (
    <Box>
      <Text as="h3" fontSize="lg" fontWeight={"semibold"}>
        {day}
      </Text>
      <Box
        border={`1px solid ${bgColor}`}
        borderRadius={"5"}
        p={"2"}
        w={"100%"}
      >
        <Flex>
          {/* Standard mode */}
          <FormControl mr={2}>
            <FormLabel mb={1}>Default modus:</FormLabel>
            <Select
              value={standardMode}
              onChange={(e) => {
                changeSchedule({
                  Default: e.target.selectedOptions[0].value,
                  Mode: differingMode ?? null,
                  Start: `${startHour}${startMinute}`,
                  End: `${endHour}${endMinute}`,
                });
              }}
            >
              <option value={MODE.closed}>Låst</option>
              <option value={MODE.open}>Åpen</option>
              <option value={MODE.authorized_cards}>Autoriserte brikker</option>
              <option value={MODE.all}>Alle brikker</option>
            </Select>
          </FormControl>
        </Flex>

        {/* Avvikende modus */}
        <FormControl>
          <FormLabel mb={1}>Avvikende modus:</FormLabel>
          <Select
            value={differingMode}
            onChange={(e) => {
              changeSchedule({
                Default: standardMode,
                Mode:
                  e.target.selectedOptions[0].value === MODE.none
                    ? null
                    : e.target.selectedOptions[0].value,
                Start:
                  e.target.selectedOptions[0].value === MODE.none
                    ? null
                    : `${startHour}${startMinute}`,
                End:
                  e.target.selectedOptions[0].value === MODE.none
                    ? null
                    : `${endHour}${endMinute}`,
              });
            }}
          >
            <option value={MODE.none}>Ingen</option>
            <option value={MODE.closed}>Låst</option>
            <option value={MODE.open}>Åpen</option>
            <option value={MODE.authorized_cards}>Autoriserte brikker</option>
            <option value={MODE.all}>Alle brikker</option>
          </Select>
        </FormControl>

        {/* TODO: Add validation to time inputs */}
        <Flex mt={2}>
          <FormControl mr={1}>
            <FormLabel mb={1}>Start:</FormLabel>
            <Flex>
              <Select
                isDisabled={differingMode === undefined}
                mr={1}
                value={differingMode === undefined ? "--" : startHour}
                onChange={(e) => {
                  changeSchedule({
                    Default: standardMode,
                    Mode: differingMode ?? null,
                    Start: isValidTime(e.target.selectedOptions[0].value)
                      ? `${e.target.selectedOptions[0].value}${startMinute}`
                      : null,
                    End: `${endHour}${endMinute}`,
                  });
                }}
              >
                {hourList.map((time) => (
                  <option key={time} value={time}>
                    {time}
                  </option>
                ))}
              </Select>
              <Select
                isDisabled={differingMode === undefined}
                value={differingMode === undefined ? "--" : startMinute}
                onChange={(e) => {
                  changeSchedule({
                    Default: standardMode,
                    Mode: differingMode ?? null,
                    Start: isValidTime(e.target.selectedOptions[0].value)
                      ? `${startHour}${e.target.selectedOptions[0].value}`
                      : null,
                    End: `${endHour}${endMinute}`,
                  });
                }}
              >
                <option value={undefined}>--</option>
                <option value={"00"}>00</option>
                <option value={"30"}>30</option>
              </Select>
            </Flex>
          </FormControl>
          <FormControl ml={1}>
            <FormLabel mb={1}>Slutt:</FormLabel>
            <Flex>
              <Select
                isDisabled={differingMode === undefined}
                mr={1}
                value={differingMode === undefined ? "--" : endHour}
                onChange={(e) => {
                  changeSchedule({
                    Default: standardMode,
                    Mode: differingMode ?? null,
                    Start: `${startHour}${startMinute}`,
                    End: isValidTime(e.target.selectedOptions[0].value)
                      ? `${e.target.selectedOptions[0].value}${endMinute}`
                      : null,
                  });
                }}
              >
                {hourList.map((time) => (
                  <option key={time} value={time}>
                    {time}
                  </option>
                ))}
              </Select>
              <Select
                isDisabled={differingMode === undefined}
                value={differingMode === undefined ? "--" : endMinute}
                onChange={(e) => {
                  changeSchedule({
                    Default: standardMode,
                    Mode: differingMode ?? null,
                    Start: `${startHour}${startMinute}`,
                    End: isValidTime(e.target.selectedOptions[0].value)
                      ? `${endHour}${e.target.selectedOptions[0].value}`
                      : null,
                  });
                }}
              >
                <option value={undefined}>--</option>
                <option value={"00"}>00</option>
                <option value={"30"}>30</option>
              </Select>
            </Flex>
          </FormControl>
        </Flex>
      </Box>
    </Box>
  );
};

export default ScheduleDay;
