import { z } from "zod";

const NewUser = z.object({
  Name: z.string().min(1, "Navnet må inneholde minst ett tegn"),
  Email: z.coerce
    .string()
    .email({ message: "Venligst fyll inn en gyldig epost" })
    .min(5, "Eposten må inneholde minst 5 tegn"),
  City: z.string().nullable(),
  PostalCode: z.number().nullable(),
  StreetName: z.string().min(3, "Gatenavnet må inneholde minst 3 tegn"),
  Apartment: z.string().nullable(),
  StreetNumber: z.number().nullable(),
  Letter: z.string().nullable(),
});

const ExportUser = z.object({
  Navn: z.string(),
  Epost: z.string(),
  Brikke: z.string(),
  Adresse: z.string(),
  Sted: z.string(),
});

const User = z.object({
  Name: z.string(),
  Email: z.string(),
  Apartment: z.string().nullable(),
  City: z.string().nullable(),
  PostalCode: z.number().nullable(),
  StreetName: z.string().nullable(),
  StreetNumber: z.number().nullable(),
  Letter: z.string().nullable(),
});

const TagUser = User.extend({
  TagId: z.string(),
});

const Users = z.array(User);
const TagUsers = z.array(TagUser);

export { User, Users, TagUser, NewUser, ExportUser };
export type UserType = z.infer<typeof User>;
export type TagUserType = z.infer<typeof TagUser>;
export type UsersType = z.infer<typeof Users>;
export type TagUsersType = z.infer<typeof TagUsers>;
export type ExportUserType = z.infer<typeof ExportUser>;
