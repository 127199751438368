import React, { useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../styles/variables";
import { ReactComponent as Delete } from "../../assets/icons/Delete.svg";
import { Tr, Td, Badge, useDisclosure } from "@chakra-ui/react";
import { custom_date } from "../../utilities/customDate.js";
import NodeTableElementText from "./NodeTableElementText";

const NodeElement = ({
  name,
  version,
  batteryLevel,
  signalStrength,
  lastAlive,
  lastStatus,
  nodeId,
  deleteNode,
  getNodes,
}) => {
  const nodesName = name?.replace(/_/g, " ");

  const deleteModal = useDisclosure();
  const [lastAliveTime, setlastAliveTime] = useState(
    lastAlive && custom_date(lastAlive)
  );
  const [lastStatusTime, setlastStatusTime] = useState(
    lastStatus && custom_date(lastStatus)
  );
  const { url } = useRouteMatch();

  const confirmDeletingNodeHandler = () => {
    deleteNode(nodeId);
  };
  return (
    <>
      <Tr>
        <NodeTableElementText
          tableValue={nodesName}
          editValue="ThingName"
          nodeId={nodeId}
          getNodes={getNodes}
        />
        <TableData>{version || <Badge>N/A</Badge>}</TableData>
        <TableData>
          {batteryLevel ? (
            parseFloat(batteryLevel).toFixed(2)
          ) : (
            <Badge>N/A</Badge>
          )}
        </TableData>
        <TableData>{signalStrength || <Badge>N/A</Badge>}</TableData>
        <TableData>{lastAliveTime || <Badge>N/A</Badge>}</TableData>
        <TableData>{lastStatusTime || <Badge>N/A</Badge>}</TableData>
        <TableData>
          <LogLink
            exact="true"
            to={{
              pathname: `${url}/${name}`,
              state: {
                nodeId: nodeId,
              },
            }}
          >
            Se detaljer
          </LogLink>
        </TableData>
        {/* <TableData onClick={() => deleteModal.onOpen()}>
          <IconDelete title="slett" onClick={() => deleteModal.onOpen()} />
        </TableData> */}
      </Tr>
      {/* <Modal
        motionPreset="slideInBottom"
        isCentered
        isOpen={deleteModal.isOpen}
        onClose={deleteModal.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Er du sikker at du vil slette denne låsen?
          </ModalHeader>
          <ModalCloseButton />
          <ModalFooter>
            <Button
              colorScheme="teal"
              variant="solid"
              mr={3}
              onClick={confirmDeletingNodeHandler}
            >
              Ja
            </Button>
            <Button
              variant="ghost"
              colorScheme="teal"
              mr={3}
              onClick={() => deleteModal.onClose()}
            >
              Nei
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal> */}
    </>
  );
};

const LogLink = styled(Link)`
  position: relative;
  padding: 0.15rem;
  &:hover {
    border-bottom: 3px solid ${colors.greenLight};
  }
`;

const TableData = styled(Td)`
  &:hover {
    background-color: ${colors.greyLight};
  }
`;

const IconDelete = styled(Delete)`
  width: 0.65rem;
  height: 0.65rem;
  cursor: pointer;
`;

export default NodeElement;
