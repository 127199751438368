import React from "react";
import styled from "styled-components";
import { colors, fonts } from "../styles/variables";
import { Tr, Td } from "@chakra-ui/react";
import {custom_date} from "../utilities/customDate"
const LogEl = ({ tag, eventType, created }) => {
  const event = eventType.replace(/_/g, " ");

  return (
    <>
      <Tr>
        <TableData>{event}</TableData>
        <TableData>{custom_date(created)}</TableData>
        <TableData>{tag}</TableData>
      </Tr>
    </>
  );
};
const TableData = styled(Td)`
  &:hover {
    background-color: ${colors.greyLight};
  }
`;

export default LogEl;
