import * as XLSX from "xlsx";
import { ImportError } from "../types/importError";
import { SafeParseError, ZodSchema } from "zod";

// Formats Validation errors to be displayed
const getErrorStrings = (
  validationResult: SafeParseError<any>
): ImportError[] => {
  var errorStrings: ImportError[] = [];

  validationResult.error.errors.forEach((zodError) => {
    const val = errorStrings.find((error) => error.type === zodError.path[1]);
    if (val !== undefined) {
      val.lines.push(Number(zodError.path[0]) + 1);
      return;
    }
    errorStrings.push({
      type: String(zodError.path[1]),
      lines: [Number(zodError.path[0]) + 1],
    });
  });

  return errorStrings;
};

// Imports data from Excel and validates it
const importDataFromExcel = async <Type>(
  file: any,
  schema: ZodSchema<Type>,
  setImportErrorMessage: (errorStrings: ImportError[]) => void,
  onOpen: () => void,
  sendData: (data: Type) => void
) => {
  const data = await file.arrayBuffer();
  /* data is an ArrayBuffer */
  const workbook = XLSX.read(data);
  const workSheet = workbook.Sheets[workbook.SheetNames[0]];
  const jsonData = XLSX.utils.sheet_to_json(workSheet, { defval: "" });

  const validationResult = schema.safeParse(jsonData);

  if (!validationResult.success) {
    const errorStrings = getErrorStrings(validationResult);

    setImportErrorMessage(errorStrings);
    onOpen();
    return;
  }

  sendData(validationResult.data);
};

export default importDataFromExcel;
