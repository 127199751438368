import {
  Thead,
  Tr,
  Th,
  Spinner,
  Tbody,
  Table,
  Text,
  Tag,
  TagRightIcon,
  TagLabel,
  Flex,
  Icon,
  IconButton,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { colors } from "../../styles/variables";
import ContainerRow from "./ContainerRow";
import { ContainerType } from "../../validators/Container";
import { ReactComponent as Sorting } from "../../assets/icons/Sorting.svg";
import { ReactComponent as ExclamationMark } from "../../assets/icons/ExclamationMark.svg";

interface ContainerTableProps {
  containerList: ContainerType[] | undefined;
  isSuccess: boolean;
  isLoading: boolean;
  noContainersMessage: string;
}

const ContainerTable = ({
  containerList,
  isSuccess,
  isLoading,
  noContainersMessage,
}: ContainerTableProps) => {
  const [order, setOrder] = useState("ASC");
  const [containers, setContainers] = useState<ContainerType[]>([]);

  useEffect(() => {
    if (containerList) {
      setContainers(containerList);
    }
  }, [containerList]);

  const sorting = (colName: string) => {
    if (order === "ASC") {
      const sorted = [...containers].sort((a, b) => {
        return (a[colName as keyof ContainerType] ?? "z") >
          (b[colName as keyof ContainerType] ?? "z")
          ? 1
          : -1;
      });
      setContainers(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...containers].sort((a, b) =>
        (a[colName as keyof ContainerType] ?? "z") <
        (b[colName as keyof ContainerType] ?? "z")
          ? 1
          : -1
      );
      setContainers(sorted);
      setOrder("ASC");
    }
  };

  return (
    <Table size={"sm"} variant="simple" colorScheme={"teal"}>
      <Thead>
        <Tr>
          <Th>
            <Flex alignItems={"center"}>
              <Text fontSize={10}>Navn</Text>
              <IconButton
                size={"xs"}
                variant={"outline"}
                ml={1}
                icon={<Icon as={Sorting} />}
                aria-label="sort by name"
                onClick={() => sorting("Name")}
              />
            </Flex>
          </Th>
          <Th>
            <Flex alignItems={"center"}>
              <Text fontSize={10}>Lokasjon</Text>
              <IconButton
                size={"xs"}
                variant={"outline"}
                ml={1}
                icon={<Icon as={Sorting} />}
                aria-label="sort by unit name"
                onClick={() => sorting("UnitName")}
              />
            </Flex>
          </Th>
          <Th>
            <Flex alignItems={"center"}>
              <Text fontSize={10}>Fraksjon</Text>
              <IconButton
                size={"xs"}
                variant={"outline"}
                ml={1}
                icon={<Icon as={Sorting} />}
                aria-label="sort by fraction"
                onClick={() => sorting("Fraction")}
              />
            </Flex>
          </Th>
          <Th>
            <Flex alignItems={"center"}>
              <Text fontSize={10}>Innkasttype</Text>
              <IconButton
                size={"xs"}
                variant={"outline"}
                ml={1}
                icon={<Icon as={Sorting} />}
                aria-label="sort by type"
                onClick={() => sorting("Type")}
              />
            </Flex>
          </Th>
          <Th>
            <Flex>
              <Text fontSize={10} title="Seneste tidspunkt den har rapportert åpnet eller seneste tidspunkt for statusrapport">Sist brukt</Text>
              {/* <SortingIcon onClick={()=>sorting("LastUse")}/> */}
            </Flex>
          </Th>
          <Th>
            <Flex>
              <Text fontSize={10} title="Seneste avviste forsøk på å åpne container">Siste Feil</Text>
              {/* <SortingIcon onClick={()=>sorting("LastFail")}/> */}
            </Flex>
          </Th>
          <Th>
            <Flex>
              <Text fontSize={10}>I drift siden</Text>
              {/* <SortingIcon onClick={()=>sorting("Created")}/> */}
            </Flex>
          </Th>
          <Th>
            <Flex alignItems={"center"}>
              <Text fontSize={10}>Størrelse</Text>
              <IconButton
                size={"xs"}
                variant={"outline"}
                ml={1}
                icon={<Icon as={Sorting} />}
                aria-label="sort by size"
                onClick={() => sorting("Size")}
              />
            </Flex>
          </Th>
          <Th>
            <Flex alignItems={"center"}>
              <Text fontSize={10}>Koordinater</Text>
              <IconButton
                size={"xs"}
                variant={"outline"}
                ml={1}
                icon={<Icon as={Sorting} />}
                aria-label="sort by coordinates"
                onClick={() => sorting("Coordinates")}
              />
            </Flex>
          </Th>
          <Th>
            <Flex>
              <Text fontSize={10} title="Er sat aktiv (container.active)">Driftstatus</Text>
            </Flex>
          </Th>
          <Th>
            <Flex>
              <Text fontSize={10} title="Viser 'fungerer ikke' om systemet ikke har mottatt en rapport innenfor 24 timer (container.operative)">
                Rapportert status
                </Text>
            </Flex>
          </Th>
          <Th>
            <Flex alignItems={"center"}>
              <Text fontSize={10}>Låseenhet</Text>
              <IconButton
                size={"xs"}
                variant={"outline"}
                ml={1}
                icon={<Icon as={Sorting} />}
                aria-label="sort by node"
                onClick={() => sorting("Node")}
              />
            </Flex>
          </Th>
          <Th>
            <Text fontSize={10}>Ukeplan</Text>
          </Th>
          <Th>
            <Text>Logg</Text>
          </Th>
          <Th>
            <Text fontSize={10}>Slett</Text>
          </Th>
        </Tr>
      </Thead>
      {isLoading ? (
        <Flex pt={"1.5rem"} w={"100%"} h={"4rem"} justifyContent={"center"}>
          <Spinner size="lg" color={colors.greenMain} />
        </Flex>
      ) : containers.length === 0 ? (
        <Tag variant="outline" size="md">
          <TagRightIcon as={ExclamationMark} />
          <TagLabel>{noContainersMessage}</TagLabel>
        </Tag>
      ) : (
        <Tbody>
          {containers.map((container) => (
            <ContainerRow key={container.Id} container={container} />
          ))}
        </Tbody>
      )}
    </Table>
  );
};

export default ContainerTable;
