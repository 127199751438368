import React, { useState } from "react";
import styled from "styled-components";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { colors } from "../styles/variables";

const Breadcrumbs = () => {
  const urlPaths = useLocation().pathname.split("/").slice(1);
  console.log(urlPaths);
  function editPath(string) {
    let home;
    if (string === "index.html" || string === "") {
      return (home = "Hjem");
    } else {
      return string.charAt(0).toUpperCase() + string.slice(1, 15);
    }
  }

  const makeUrl = (idx) => {
    let url = [];
    urlPaths.forEach((path, index) => {
      index <= idx && url.push(path);
    });
    return url.join("/");
  };

  const breadcrumbsMapped = urlPaths.map((path, idx) => {
    return (
      <BreadcrumbItem key={idx}>
        <BreadcrumbEl to={"/" + makeUrl(idx)}>{editPath(path)}</BreadcrumbEl>
      </BreadcrumbItem>
    );
  });

  return <BreadcrumbContainer>{breadcrumbsMapped}</BreadcrumbContainer>;
};

const BreadcrumbContainer = styled(Breadcrumb)`
  width: calc(100%-15rem);
  height: 3rem;
  padding: 0.5rem 2rem;
  display: flex;
  align-items: center;
  background-color: ${colors.greyMedium};
`;

const BreadcrumbEl = styled(Link)`
  border-bottom: 3px solid ${colors.greyMedium};
  font-size: 0.85rem;
  &:hover {
    border-bottom: 3px solid ${colors.greenMain};
    cursor: pointer;
  }
`;
export default Breadcrumbs;
