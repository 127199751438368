import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useAddUserMutation } from "../../features/api/ApiSlice";
import { NewUser, UserType } from "../../validators/User";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import isErrorObject from "../../utilities/isErrorObject";

interface AddUserModalContentProps {
  closeModal: () => void;
}

const AddUSerModalContent = ({
  closeModal,
}: AddUserModalContentProps): JSX.Element => {
  const [addUser, { error, isLoading, isSuccess }] = useAddUserMutation();
  const toast = useToast();
  const [errorMessage, setErrorMessage] = useState<string>();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UserType>({ resolver: zodResolver(NewUser) });

  // Handle error respose from API
  useEffect(() => {
    const errorMsg = error
      ? "data" in error && isErrorObject(error.data)
        ? error.data.exception
        : ""
      : "";
    setErrorMessage(errorMsg);
  }, [error]);

  // Handle actions on create success
  useEffect(() => {
    if (isSuccess) {
      closeModal();
      toast({
        title: "Bruker opprettet",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [isSuccess, closeModal, toast]);

  return (
    <>
      <ModalHeader>Ny bruker</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <form>
          {/* Name */}
          <FormControl isInvalid={!!errors.Name?.message}>
            <FormLabel>Navn</FormLabel>
            <Input autoComplete="off" {...register("Name")} />
            <FormErrorMessage>
              {errors.Name?.message?.toString()}
            </FormErrorMessage>
          </FormControl>

          {/* Email */}
          <FormControl isInvalid={!!errors.Email?.message}>
            <FormLabel>Epost</FormLabel>
            <Input autoComplete="off" {...register("Email")} />
            <FormErrorMessage>
              {errors.Email?.message?.toString()}
            </FormErrorMessage>
          </FormControl>
          <Flex>
            {/* Postal Code */}
            <FormControl pr={2}>
              <FormLabel>Postnummer</FormLabel>
              <Input
                type="number"
                {...register("PostalCode", {
                  setValueAs: (code) =>
                    code === "" ? null : parseInt(code, 10),
                })}
              />
              <FormErrorMessage>
                {errors.PostalCode?.message?.toString()}
              </FormErrorMessage>
            </FormControl>

            {/* City */}
            <FormControl pl={2}>
              <FormLabel>Sted</FormLabel>
              <Input
                {...register("City", {
                  setValueAs: (city) => (city === "" ? null : city),
                })}
              />
              <FormErrorMessage>
                {errors.PostalCode?.message?.toString()}
              </FormErrorMessage>
            </FormControl>
          </Flex>

          {/* StreetName */}
          <FormControl isInvalid={!!errors.StreetName?.message}>
            <FormLabel>Gatenavn</FormLabel>
            <Input autoComplete="off" {...register("StreetName")} />
            <FormErrorMessage>
              {errors.StreetName?.message?.toString()}
            </FormErrorMessage>
          </FormControl>

          <Flex>
            {/* StreetNumber */}
            <FormControl pr={2}>
              <FormLabel>Husnummer</FormLabel>
              <Input
                type="number"
                {...register("StreetNumber", {
                  setValueAs: (number) =>
                    number === "" ? null : parseInt(number, 10),
                })}
              />
              <FormErrorMessage>
                {errors.StreetNumber?.message?.toString()}
              </FormErrorMessage>
            </FormControl>

            {/* Letter */}
            <FormControl px={2}>
              <FormLabel>Husbokstav</FormLabel>
              <Input
                type="text"
                {...register("Letter", {
                  setValueAs: (letter) => (letter === "" ? null : letter),
                })}
              />
              <FormErrorMessage>
                {errors.Letter?.message?.toString()}
              </FormErrorMessage>
            </FormControl>

            {/* Apt number */}
            <FormControl pl={2}>
              <FormLabel>Leilighetsnummer</FormLabel>
              <Input
                type="text"
                {...register("Apartment", {
                  setValueAs: (apt) => (apt === "" ? null : apt),
                })}
              />
              <FormErrorMessage>
                {errors.Apartment?.message?.toString()}
              </FormErrorMessage>
            </FormControl>
          </Flex>
        </form>

        <Text color={"red"}>{errorMessage}</Text>
      </ModalBody>
      <ModalFooter>
        <Button
          isLoading={isLoading}
          colorScheme="teal"
          mr={3}
          onClick={handleSubmit((user) => {
            addUser(user);
          })}
        >
          Lagre
        </Button>
        <Button
          variant="ghost"
          colorScheme="teal"
          mr={3}
          onClick={() => closeModal()}
        >
          Avbryt
        </Button>
      </ModalFooter>
    </>
  );
};

export default AddUSerModalContent;
