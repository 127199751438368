import React, { useState, useRef, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../styles/variables";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  TableContainer,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Stack,
  StackDivider,
  HStack,
  Input,
  Checkbox,
  Text,
  Spinner,
} from "@chakra-ui/react";

import Location from "./Location";
import ExportToExcelLocations from "./ExportToExcelLocations";
import { ReactComponent as Sorting } from "../../assets/icons/Sorting.svg";
import axios from "axios";

import * as XLSX from "xlsx";
import { AddIcon } from "@chakra-ui/icons";

const Locations = () => {
  const locationsFromStore = useSelector(
    (state) => state.locationsReducer.locations
  );
  // let locations = useSelector((state) => state.locationsReducer.locations);
  const [locations, setLocations] = useState(
    useSelector((state) => state.locationsReducer.locations)
  );

  const [order, setOrder] = useState("ASC");
  const numRef = useRef(null);

  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { url } = useRouteMatch();

  const fileName = "Lokasjoner";

  const NameInputRef = useRef();
  const OwnerInputRef = useRef();
  const DealIdInputRef = useRef();
  const CityInputRef = useRef();
  const PostalCodeInputRef = useRef();
  const StreetNameInputRef = useRef();
  const StreetNumberInputRef = useRef();
  const LetterInputRef = useRef();
  const isWorkingInputRef = useRef();

  const token = localStorage.getItem("API_token");
  const [isChecked, setIsChecked] = useState(false);

  let headers = {
    authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    getLocations();
  }, []);

  const getLocations = () => {
    axios
      .get(
        `https://gpshu4lon5.execute-api.eu-north-1.amazonaws.com/Test/units`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        // dispatch(updateLocation(res.data));
        setLocations(res.data);
      });
  };

  const createLocationHandler = () => {
    let location = {
      Name: "",
      Owner: "",
      City: "",
      PostalCode: null,
      StreetName: "",
      StreetNumber: null,
      Letter: "",
      Active: false,
    };
    location = {
      Name: NameInputRef.current.value,
      Owner: OwnerInputRef.current.value,
      City: CityInputRef.current.value,
      PostalCode: PostalCodeInputRef.current.value,
      StreetName: StreetNameInputRef.current.value,
      StreetNumber: StreetNumberInputRef.current.value,
      Letter: LetterInputRef.current.value,
      Active: isWorkingInputRef.current.checked ? true : false,
    };

    axios
      .post(
        `https://gpshu4lon5.execute-api.eu-north-1.amazonaws.com/Test/units`,
        [location],
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          getLocations();
          onClose();
        }
      });
  };

  const deleteLocationHandler = (id, rangeKey) => {
    axios
      .delete(
        `https://gpshu4lon5.execute-api.eu-north-1.amazonaws.com/Test/units/${id}?created=${rangeKey}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.status === 200)
          // dispatch(
          //   updateLocation(locations.filter((location) => location.Id !== id))
          // );
          getLocations();
      });
  };

  const [items, setItems] = useState([]);
  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, { defval: "" });
        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      d.forEach((obj, idx) => {
        let keysArr = [
          "Name",
          "Owner",
          "City",
          "StreetName",
          "PostalCode",
          "StreetNumber",
          "Letter",
        ];
        let keys = Object.keys(obj);
        keys.forEach((key, index) => {
          let value = obj[key];
          delete obj[key];
          obj[keysArr[index]] = value;
        });
      });
      axios
        .post(
          `https://gpshu4lon5.execute-api.eu-north-1.amazonaws.com/Test/units`,
          d,
          {
            headers: headers,
          }
        )
        .then((res) => {
          if (res.status === 200) {
            getLocations();
          }
        });
    });
  };

  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...locations].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setLocations(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...locations].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setLocations(sorted);
      setOrder("ASC");
    }
  };

  return (
    <Wrapper>
      <Content>
        <Title>Lokasjoner</Title>
        <BreakingLine />
        <ControlTableButtonsContainer>
          <Button
            variant={"outline"}
            colorScheme="teal"
            size={"xs"}
            rightIcon={<AddIcon />}
            mx={1}
            onClick={onOpen}
          >
            Ny lokasjon
          </Button>
          {/* <AddLocation onClick={onOpen} variant="subtle" size="md">
            <TagLabel>Legg til en ny lokasjon</TagLabel>
            <TagRightIcon boxsize={3} as={AddGreen} />
          </AddLocation> */}
          <Button
            variant={"outline"}
            colorScheme="blue"
            size={"xs"}
            rightIcon={<AddIcon />}
            mx={1}
            onChange={(e) => {
              const file = e.target.files[0];
              readExcel(file);
            }}
          >
            <label style={{ cursor: "pointer" }}>
              <Input type="file" display="none" />
              Importer fra fil
            </label>
          </Button>

          <ExportToExcelLocations fileName={fileName} tableData={locations} />
        </ControlTableButtonsContainer>
        <Modal
          isCentered
          closeOnOverlayClick={false}
          isOpen={isOpen}
          onClose={onClose}
          scrollBehavior={"inside"}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader a="b">Ny lokasjon</ModalHeader>
            <ModalCloseButton />

            <ModalBody>
              <Stack
                spacing={4}
                divider={<StackDivider borderColor="gray.600" />}
                direction={"column"}
              >
                <Stack spacing={3}>
                  <Stack spacing={1}>
                    <Text fontSize="xs">Navn</Text>
                    <Input
                      focusBorderColor="teal.400"
                      variant="filled"
                      ref={NameInputRef}
                    />
                  </Stack>
                  <Stack spacing={1}>
                    <Text fontSize="xs">Eier</Text>
                    <Input
                      focusBorderColor="teal.400"
                      variant="filled"
                      ref={OwnerInputRef}
                    />
                  </Stack>
                  <Stack spacing={1}>
                    <Text fontSize="xs">Sted</Text>
                    <Input
                      focusBorderColor="teal.400"
                      variant="filled"
                      ref={CityInputRef}
                    />
                  </Stack>

                  <Stack spacing={1}>
                    <Text fontSize="xs">Gatenavn</Text>
                    <Input
                      focusBorderColor="teal.400"
                      variant="filled"
                      htmlSize={32}
                      width="auto"
                      ref={StreetNameInputRef}
                    />
                  </Stack>
                  <HStack>
                    <Stack spacing={1}>
                      <Text fontSize="xs">Gatenummer</Text>
                      <Input
                        type="number"
                        variant="filled"
                        htmlSize={4}
                        width="auto"
                        ref={StreetNumberInputRef}
                      />
                    </Stack>

                    <Stack spacing={1}>
                      <Text fontSize="xs">Bokstav</Text>
                      <Input
                        variant="filled"
                        htmlSize={4}
                        width="auto"
                        ref={LetterInputRef}
                      />
                    </Stack>
                    <Stack spacing={1}>
                      <Text fontSize="xs">Postnummer</Text>
                      <Input
                        type="number"
                        focusBorderColor="teal.400"
                        variant="filled"
                        ref={PostalCodeInputRef}
                      />
                    </Stack>
                  </HStack>
                  <Stack spacing={4}>
                    <Stack>
                      <CheckboxIcon
                        onChange={() =>
                          setIsChecked(isWorkingInputRef.current.checked)
                        }
                        colorScheme="teal"
                        spacing="1rem"
                        ref={isWorkingInputRef}
                        type="checkbox"
                      >
                        i drift
                      </CheckboxIcon>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="teal" mr={3} onClick={createLocationHandler}>
                Lagre
              </Button>
              <Button
                variant="ghost"
                colorScheme="teal"
                mr={3}
                onClick={onClose}
              >
                Avbryt
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <TableMainContainer>
          <Table variant="simple" size="sm" colorScheme={"teal"}>
            <Thead>
              <Tr>
                <Th>
                  <TableHeader>Detaljer</TableHeader>
                </Th>
                <Th>
                  <ThWrapper>
                    <TableHeader>Navn</TableHeader>
                    <SortingIcon onClick={() => sorting("Name")} />
                  </ThWrapper>
                </Th>
                <Th>
                  <ThWrapper>
                    <TableHeader>Eier</TableHeader>
                    <SortingIcon onClick={() => sorting("Owner")} />
                  </ThWrapper>
                </Th>
                <Th>
                  <ThWrapper>
                    <TableHeader>Sted</TableHeader>
                    <SortingIcon onClick={() => sorting("City")} />
                  </ThWrapper>
                </Th>
                <Th>
                  <ThWrapper>
                    <TableHeader>Postnummer</TableHeader>
                    <SortingIcon onClick={() => sorting("PostalCode")} />
                  </ThWrapper>
                </Th>
                <Th>
                  <ThWrapper>
                    <TableHeader>Gatenavn</TableHeader>
                    <SortingIcon onClick={() => sorting("StreetName")} />
                  </ThWrapper>
                </Th>
                <Th>
                  <ThWrapper>
                    <TableHeader>Gatenummer</TableHeader>
                    <SortingIcon onClick={() => sorting("StreetNumber")} />
                  </ThWrapper>
                </Th>
                <Th>
                  <ThWrapper>
                    <TableHeader>Husbokstav</TableHeader>
                    <SortingIcon onClick={() => sorting("Letter")} />
                  </ThWrapper>
                </Th>
                <Th>
                  <TableHeader>Status</TableHeader>
                </Th>
                <Th>
                  <TableHeader>Slett</TableHeader>
                </Th>
              </Tr>
            </Thead>

            <Tbody>
              {locations.length !== 0 &&
                locations.map((location, index) => (
                  <Location
                    id={location.Id}
                    rangeKey={location.Created}
                    key={index}
                    url={url}
                    name={location.Name}
                    owner={location.Owner}
                    city={location.City}
                    postalCode={location.PostalCode}
                    streetName={location.StreetName}
                    streetNumber={location.StreetNumber}
                    houseLetter={location.Letter}
                    active={location.Active}
                    deleteUnit={deleteLocationHandler}
                    getLocations={getLocations}
                  />
                ))}
            </Tbody>
          </Table>
          {locations.length === 0 && (
            <SpinnerWrapper>
              <Spinner size="lg" color={colors.greenMain} />
            </SpinnerWrapper>
          )}
        </TableMainContainer>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 92.5vh;
  display: flex;
  flex: 1;
  overflow: auto;
  background-color: ${colors.greyLight};
`;

const Content = styled.div`
  font-size: 1.25rem;
  padding: 2rem;
  margin: 2rem;
  border-radius: 5px;
  font-family: "Roboto";
  background-color: white;
  height: auto;
  overflow: auto;
`;
const Title = styled.h1`
  font-weight: 600;
`;
const BreakingLine = styled.hr`
  border-top: 1px solid lightgrey;
`;

const ControlTableButtonsContainer = styled.div`
  margin-top: 1rem;
  width: 30rem;
  display: flex;
  align-items: center;
`;

const ThWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const TableHeader = styled(Text)`
  font-size: 0.6rem;
  font-weight: bold;
`;
const SortingIcon = styled(Sorting)`
  width: 1rem;
  height: 1rem;
  margin-left: 0.75rem;
  cursor: pointer;
`;
const CheckboxIcon = styled(Checkbox)`
  span:first-of-type {
    border: 1px solid lightgrey;
  }
`;

const TableMainContainer = styled(TableContainer)`
  margin-top: 3rem;
`;

const SpinnerWrapper = styled.div`
  width: 100%;
  height: 4rem;
  padding-top: 1.5rem;
  display: flex;
  justify-content: center;
`;

export default Locations;
