import React, { useState, useEffect } from "react";
import { colors } from "../../styles/variables";
import {
  Td,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import { ReactComponent as Edit } from "../../assets/icons/Edit.svg";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEditContainerMutation } from "../../features/api/ApiSlice";
import { EditValueType, EditValueValidator } from "../../validators/EditValue";

interface ContainerTableElementTextProps {
  value: string | null;
  valueName: string;
  valueTitle: string;
  containerId: string;
}

const ContainerTableElementText = ({
  value,
  valueName,
  valueTitle,
  containerId,
}: ContainerTableElementTextProps) => {
  const [editContainer, { isSuccess, isLoading }] = useEditContainerMutation();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [showEditIcon, setShowEditIcon] = useState(false);
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EditValueType>({
    resolver: zodResolver(EditValueValidator),
    shouldUnregister: true,
    defaultValues: {
      newValue: value ?? undefined,
    },
  });

  // Handle actions on create success
  useEffect(() => {
    if (isSuccess) {
      onClose();
      toast({
        title: "Container oppdatert",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [isSuccess, onClose, toast]);

  return (
    <>
      <Td
        pos={"relative"}
        _hover={{ bgColor: `${colors.greyLight}` }}
        onMouseEnter={() => setShowEditIcon(true)}
        onMouseLeave={() => setShowEditIcon(false)}
        onClick={onOpen}
      >
        {value}
        {showEditIcon && (
          <Edit
            style={{
              width: "0.65rem",
              height: "0.65rem",
              cursor: "pointer",
              position: "absolute",
              left: "-12px",
              top: "-5px",
              margin: "1rem",
            }}
          />
        )}
      </Td>

      <Modal
        motionPreset="slideInBottom"
        isCentered
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton size="sm" />
          <ModalHeader>Rediger {valueTitle.toLocaleLowerCase()}</ModalHeader>
          <ModalBody>
            <form>
              <FormControl isInvalid={!!errors.newValue?.message}>
                <FormLabel>{valueTitle}</FormLabel>
                <Input autoComplete="off" {...register("newValue")} />
                <FormErrorMessage>
                  {errors.newValue?.message?.toString()}
                </FormErrorMessage>
              </FormControl>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="teal"
              variant="solid"
              isLoading={isLoading}
              mr={3}
              onClick={handleSubmit((editObject) => {
                if (editObject.newValue === value) {
                  onClose();
                  return;
                }
                const editContainerDTO = {
                  Id: containerId,
                  [valueName]: editObject.newValue,
                };
                editContainer(editContainerDTO);
              })}
            >
              Lagre
            </Button>
            <Button
              variant="ghost"
              colorScheme="teal"
              mr={3}
              onClick={() => onClose()}
            >
              Avbryt
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ContainerTableElementText;
