import React, { useEffect } from "react";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import Breadcrumbs from "./Breadcrumbs";
import awsExports from "../aws-exports";
import { Amplify, Auth } from "aws-amplify";
import { Box, Flex } from "@chakra-ui/react";

Amplify.configure(awsExports);

const MainPage = ({ signOut, user, children }: any): JSX.Element => {
  const signOutHandler = () => {
    signOut();
    localStorage.clear();
  };

  const setCurrentSessionToken = async () => {
    const currentSession = await Auth.currentSession();
    const jwtToken = currentSession?.getIdToken().getJwtToken();
    localStorage.setItem("API_token", jwtToken);
  };

  // TODO: Check if this needs to be done here??
  useEffect(() => {
    setCurrentSessionToken();
  }, []);

  return (
    <Box height={"100vh"} width={"100vw"}>
      <Topbar signOut={signOutHandler} user={user} />
      <Flex height={"91.5%"} width={"100%"}>
        <Sidebar />
        <Flex
          width={"100%"}
          height={"100%"}
          flexDir={"column"}
          overflow={"scroll"}
        >
          <Breadcrumbs />
          {children}
        </Flex>
      </Flex>
    </Box>
  );
};

export default MainPage;
