import React, { useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../styles/variables";

import {
  Tr,
  Td,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { ReactComponent as Delete } from "../../assets/icons/Delete.svg";
import { ReactComponent as Details } from "../../assets/icons/Details.svg";
import HouseholdTableElementText from "./HouseholdTableElementText";
import { addZeroToPostalCode } from "../../utilities/FormatPostalCode";
import { URL_NAMES } from "../../types/urlNames";
const Household = ({
  id,
  city,
  postalCode,
  streetName,
  streetNumber,
  letter,
  apartment,
  dealId,
  sectionId,
  collectionId,
  businessType,
  deleteHousholdHandler,
  units,
  location,
  locationId,
  getHouseholds,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const confirmDeletingUnitHandler = () => {
    deleteHousholdHandler(id);
    onClose();
  };

  return (
    <>
      <Tr>
        <TableData>
          <Link
            exact="true"
            to={{
              pathname: `${URL_NAMES.HOUSING_UNITS}/${id}`,
              // state: { householdId: id, locationId: locationId },
            }}
          >
            <IconDetails />
          </Link>
        </TableData>
        <HouseholdTableElementText
          editValue="City"
          tableValue={city}
          householdId={id}
          getHouseholds={getHouseholds}
        />
        <HouseholdTableElementText
          editValue="PostalCode"
          tableValue={postalCode}
          householdId={id}
          getHouseholds={getHouseholds}
          addZeroToPostalCode={addZeroToPostalCode}
        />
        <HouseholdTableElementText
          editValue="StreetName"
          tableValue={streetName}
          householdId={id}
          getHouseholds={getHouseholds}
        />
        <HouseholdTableElementText
          editValue="StreetNumber"
          tableValue={streetNumber}
          householdId={id}
          getHouseholds={getHouseholds}
        />
        <HouseholdTableElementText
          editValue="Letter"
          tableValue={letter}
          householdId={id}
          getHouseholds={getHouseholds}
        />
        <HouseholdTableElementText
          editValue="Apartment"
          tableValue={apartment}
          householdId={id}
          getHouseholds={getHouseholds}
        />
        <HouseholdTableElementText
          editValue="DealId"
          tableValue={dealId}
          householdId={id}
          getHouseholds={getHouseholds}
        />
        <HouseholdTableElementText
          editValue="SectionId"
          tableValue={sectionId}
          householdId={id}
          getHouseholds={getHouseholds}
        />
        <HouseholdTableElementText
          editValue="CollectionId"
          tableValue={collectionId}
          householdId={id}
          getHouseholds={getHouseholds}
        />
        <HouseholdTableElementText
          editValue="BusinessType"
          tableValue={businessType}
          householdId={id}
          getHouseholds={getHouseholds}
        />
        <HouseholdTableElementText
          editValue="UnitNames"
          tableValue={location}
          householdId={id}
          getHouseholds={getHouseholds}
        />

        <TableData onClick={onOpen}>
          <IconDelete title="slett" onClick={onOpen} />
        </TableData>
      </Tr>

      <Modal
        motionPreset="slideInBottom"
        isCentered
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Er du sikker at du vil slette denne boenheten?
          </ModalHeader>
          <ModalCloseButton />
          <ModalFooter>
            <Button
              colorScheme="teal"
              variant="solid"
              mr={3}
              onClick={() => confirmDeletingUnitHandler()}
            >
              Ja
            </Button>
            <Button variant="ghost" colorScheme="teal" mr={3} onClick={onClose}>
              Nei
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const TableData = styled(Td)`
  position: relative;
  &:hover {
    background-color: ${colors.greyLight};
  }
`;

const IconDelete = styled(Delete)`
  width: 0.65rem;
  height: 0.65rem;
  cursor: pointer;
`;

const IconDetails = styled(Details)`
  width: 0.85rem;
  height: 0.85rem;
  cursor: pointer;
`;

export default Household;
