import { z } from "zod";

const Tag = z.object({
  Id: z.string().nullable(),
  Created: z.number().nullable(),
  InternalId: z.string().nullable(),
  ExternalId: z.string().nullable(),
  Units: z.string().nullable(),
  Containers: z.string().nullable(),
  HouseholdId: z.string().nullable(),
  Owner: z.string().nullable(),
  Address: z.string().nullable(),
});

const TagWithAddress = z.object({
  Id: z.string().nullable(),
  Created: z.number().nullable(),
  InternalId: z.string().nullable(),
  ExternalId: z.string().nullable(),
  Units: z.string().nullable(),
  Containers: z.string().nullable(),
  HouseholdId: z.string().nullable(),
  Owner: z.string().nullable(),
  City: z.string().nullable(),
  PostalCode: z.number().nullable(),
  StreetName: z.string().nullable(),
  StreetNumber: z.number().nullable(),
  Letter: z.string().nullable(),
  Apartment: z.string().nullable(),
});

const ImportTag = z.object({
  InternalId: z.coerce.string().nonempty(),
  ExternalId: z.coerce.string().nonempty(),
  Owner: z.coerce.string().transform((owner) => (owner === "" ? null : owner)),
  City: z.coerce
    .string()
    .nullable()
    .transform((city) => (city === "" ? null : city)),
  PostalCode: z.coerce.number().positive(),
  StreetName: z.coerce.string().nonempty(),
  StreetNumber: z.coerce
    .number()
    .nullable()
    .transform((streetNum) => (streetNum === 0 ? null : streetNum)),
  Letter: z.coerce
    .string()
    .nullable()
    .transform((letter) => (letter === "" ? null : letter)),
  Apartment: z.coerce
    .string()
    .nullable()
    .optional()
    .transform((apt) => (apt === "" ? null : apt)),
});

const NewTag = ImportTag.extend({
  Address: z.string().nullable(),
});

const Tags = z.array(Tag);
const TagsWithAddress = z.array(TagWithAddress);
const ImportTags = z.array(ImportTag);
const NewTags = z.array(NewTag);

export {
  Tag,
  Tags,
  TagWithAddress,
  TagsWithAddress,
  NewTag,
  NewTags,
  ImportTag,
  ImportTags,
};
export type TagType = z.infer<typeof Tag>;
export type TagsType = z.infer<typeof Tags>;
export type TagWithAddressType = z.infer<typeof TagWithAddress>;
export type TagsWithAddressType = z.infer<typeof TagsWithAddress>;
export type NewTagType = z.infer<typeof NewTag>;
export type NewTagsType = z.infer<typeof NewTags>;
export type ImportTagType = z.infer<typeof ImportTag>;
export type ImportTagsType = z.infer<typeof ImportTags>;
