import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { colors } from "../../styles/variables";
import {
  Td,
  Badge,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Switch,
  Text,
  useToast,
  Box,
} from "@chakra-ui/react";
import { ReactComponent as Edit } from "../../assets/icons/Edit.svg";
import { useEditContainerMutation } from "../../features/api/ApiSlice";
import { Flex } from "@aws-amplify/ui-react";

interface ContainerTableElementBooleanProps {
  value: boolean | null;
  valueName: string;
  valueTitle: string;
  containerId: string;
}

const ContainerTableElement = ({
  value,
  valueName,
  valueTitle,
  containerId,
}: ContainerTableElementBooleanProps) => {
  const [editContainer, { isSuccess, isLoading }] = useEditContainerMutation();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [toggleValue, setToggleValue] = useState(value);

  const toast = useToast();

  // Handle actions on create success
  useEffect(() => {
    if (isSuccess) {
      onClose();
      toast({
        title: "Container oppdatert",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [isSuccess, onClose, toast]);

  return (
    <>
      <TableData
        onMouseEnter={() => setShowEditIcon(true)}
        onMouseLeave={() => setShowEditIcon(false)}
      >
        <Box onClick={onOpen}>
          {value ? (
            <Badge colorScheme="green">Aktiv</Badge>
          ) : (
            <Badge colorScheme="red">Inaktiv</Badge>
          )}
          {showEditIcon && <IconEdit />}
        </Box>
      </TableData>

      <Modal
        motionPreset="slideInBottom"
        isCentered
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton size="sm" />
          <ModalHeader>Rediger {valueTitle.toLocaleLowerCase()}</ModalHeader>
          <ModalBody>
            <Flex alignItems={"center"}>
              <Switch
                mr={"1rem"}
                onChange={() => setToggleValue(!toggleValue)}
                isChecked={toggleValue ? true : false}
                colorScheme="green"
                size="md"
              />

              <Text>{toggleValue ? "Aktiv" : "Inaktiv"}</Text>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="teal"
              variant="solid"
              mr={3}
              isLoading={isLoading}
              onClick={() => {
                if (value === toggleValue) {
                  return;
                }
                editContainer({ Id: containerId, [valueName]: toggleValue });
              }}
            >
              Lagre
            </Button>
            <Button
              variant="ghost"
              colorScheme="teal"
              mr={3}
              onClick={() => onClose()}
            >
              Avbryt
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const IconEdit = styled(Edit)`
  width: 0.65rem;
  height: 0.65rem;
  cursor: pointer;
  position: absolute;
  left: -13px;
  top: -5px;
  margin: 1rem;
`;
const TableData = styled(Td)`
  position: relative;
  &:hover {
    background-color: ${colors.greyLight};
  }
`;

export default ContainerTableElement;
