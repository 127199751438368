import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../styles/variables";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  TableContainer,
  Flex,
  Spinner,
  Box,
  Text,
  Grid,
  GridItem,
  Center,
  Tag,
} from "@chakra-ui/react";
import Log from "../Log";
import Pagination from "../Pagination";
import { useParams } from "react-router-dom";
import {
  useGetContainersByIdsQuery,
  useGetLogsQuery,
} from "../../features/api/ApiSlice";

interface RouteParams {
  id: string;
}

const ContainerLog = () => {
  const { id } = useParams<RouteParams>();
  const {
    data: logData,
    isLoading: isLogLoading,
    isError: isLogError,
  } = useGetLogsQuery({
    id: id,
    index: 0,
    count: 50,
    type: "container",
  });
  const {
    data: containerData,
    isLoading: isContainerLoading,
    isError: isContainerError,
  } = useGetContainersByIdsQuery(id);

  const [currentPage, setCurrentPage] = useState(1);
  const [logsPerPage, setLogsPerPage] = useState(10);

  useEffect(() => {
    console.log(logData);
  }, [logData]);

  const indexOfLastLog = currentPage * logsPerPage;
  const indexOfFirstLog = indexOfLastLog - logsPerPage;
  const currentLogs = logData?.events?.slice(indexOfFirstLog, indexOfLastLog);

  const paginate = (pageNum: number) => {
    setCurrentPage(pageNum);
  };
  return (
    <Flex bgColor={`${colors.greyLight}`} w={"100%"} h={"100%"}>
      <Box
        bgColor={"white"}
        p={"2.5rem"}
        m={"2rem"}
        width={"90%"}
        maxW={"1500px"}
      >
        <Text as="h1" fontWeight={"600"}>
          Containerlogg - {containerData ? containerData[0].Name : ""}:
        </Text>
        <BreakingLine />

        {isLogLoading || isContainerLoading ? (
          <Center mt={20}>
            <Spinner size="lg" color={colors.greenMain} />
          </Center>
        ) : isContainerError || isLogError ? (
          <Center mt={20}>
            <Tag colorScheme="red">
              Kunne ikke hente container eller loggdata, venligst prøv igjen
            </Tag>
          </Center>
        ) : (
          <Box>
            <Grid mt={2} templateColumns="repeat(8, 1fr)" gap={0.5}>
              {/* Navn */}
              <GridItem rowSpan={1}>
                <Text fontSize={14} fontWeight={600}>
                  Navn:
                </Text>
              </GridItem>
              <GridItem colSpan={3}>
                <Text fontSize={14}>
                  {containerData ? containerData[0].Name ?? "Ukjent" : "Ukjent"}
                </Text>
              </GridItem>
              {/* Lokasjon */}
              <GridItem rowSpan={1} colSpan={1}>
                <Text fontSize={14} fontWeight={600}>
                  Lokasjon:
                </Text>
              </GridItem>
              <GridItem colSpan={3}>
                <Text fontSize={14}>
                  {containerData
                    ? containerData[0].UnitName ?? "Ukjent"
                    : "Ukjent"}
                </Text>
              </GridItem>
              {/* Fraksjon */}
              <GridItem rowSpan={1} colSpan={1}>
                <Text fontSize={14} fontWeight={600}>
                  Fraksjon:{" "}
                </Text>
              </GridItem>
              <GridItem colSpan={3}>
                <Text fontSize={14}>
                  {containerData
                    ? containerData[0].Fraction ?? "Ukjent"
                    : "Ukjent"}
                </Text>
              </GridItem>

              {/* Node */}
              <GridItem rowSpan={1} colSpan={1}>
                <Text fontSize={14} fontWeight={600}>
                  Låseenhet:
                </Text>
              </GridItem>
              <GridItem colSpan={3}>
                <Text fontSize={14}>
                  {containerData ? containerData[0].Node ?? "Ukjent" : "Ukjent"}
                </Text>
              </GridItem>
            </Grid>

            {currentLogs?.length === 0 ? (
              <Center mt={20}>
                <Tag colorScheme="yellow">
                  Ingen loggeventer er registrert for denne enheten enda
                </Tag>
              </Center>
            ) : (
              <Box>
                <TableContainer mt={"2rem"}>
                  <Table variant="simple" size="sm" colorScheme={"teal"}>
                    <Thead>
                      <Tr>
                        <Th>Type</Th>
                        <Th>Opprettet</Th>
                        <Th>Tag</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {currentLogs?.map((log) => (
                        <Log
                          key={log.Id}
                          tag={log.Tag}
                          eventType={log.Type}
                          created={log.Created}
                        />
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
                <Pagination
                  logsPerPage={logsPerPage}
                  totalLogs={logData?.events.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Flex>
  );
};

const BreakingLine = styled.hr`
  border-top: 2px solid grey;
`;

export default ContainerLog;
