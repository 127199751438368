import React, { useState } from "react";
import { Stack, Text } from "@chakra-ui/react";
import styled from "styled-components";
const Pagination = ({ logsPerPage, totalLogs, paginate, currentPage }) => {
  const [clicked, setClicked] = useState(false);
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalLogs / logsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <PaginationList>
        {pageNumbers.map((pageNumber) => (
          <PaginationNumber
            active={currentPage === pageNumber ? true : false}
            key={pageNumber}
          >
            <NumberLink
              onClick={() => {
                paginate(pageNumber);
              }}
            >
              {pageNumber}
            </NumberLink>
          </PaginationNumber>
        ))}
      </PaginationList>
    </nav>
  );
};

const PaginationList = styled.ul`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PaginationNumber = styled.li`
  list-style-type: none;
  margin: 0.5rem;
  font-size: 0.85rem;
  color: ${(props) => (props.active ? "black" : "grey")};
  cursor: pointer;
  &:hover {
    color: black;
  }
`;

const NumberLink = styled.a``;

export default Pagination;
