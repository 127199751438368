import { z } from "zod";

const Admin = z.object({
  Created: z.string(),
  Email: z.string(),
  EmailVerified: z.boolean(),
  Enabled: z.boolean(),
  Id: z.string(),
  Status: z.string(),
  Updated: z.string(),
});

const Admins = z.array(Admin);

export { Admin, Admins };
export type AdminType = z.infer<typeof Admin>;
export type AdminsType = z.infer<typeof Admins>;
