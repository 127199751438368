import React from "react";
import { Tr, Td } from "@chakra-ui/react";
import { colors } from "../../styles/variables";
import { TagUserType } from "../../validators/User";

interface UserRowProps {
  user: TagUserType;
}

const UserRow = ({ user }: UserRowProps) => {
  return (
    <Tr>
      <Td _hover={{ bgColor: `${colors.greyLight}` }}>{user.Name}</Td>
      <Td _hover={{ bgColor: `${colors.greyLight}` }}>{`${
        user?.StreetName ?? ""
      } ${user?.StreetNumber ?? ""} ${user?.Letter ?? ""}`}</Td>
      <Td _hover={{ bgColor: `${colors.greyLight}` }}>{`${
        user?.PostalCode ?? ""
      } ${user?.City ?? ""}`}</Td>
      <Td _hover={{ bgColor: `${colors.greyLight}` }}>{user.Email}</Td>
      <Td _hover={{ bgColor: `${colors.greyLight}` }}>{user.TagId}</Td>
    </Tr>
  );
};

export default UserRow;
