import React, { useEffect, useState } from "react";
import { colors } from "../../styles/variables";
import { useParams } from "react-router-dom";
import {
  Box,
  Flex,
  HStack,
  Heading,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useGetAdminsQuery } from "../../features/api/ApiSlice";
import { AdminType } from "../../validators/Admin";

interface RouteParams {
  id: string;
}

const AdminDetails = () => {
  const { data, isLoading, isError, isSuccess } = useGetAdminsQuery();
  const { id } = useParams<RouteParams>();

  const [selectedAdmin, setSelectedAdmin] = useState<AdminType | undefined>();

  useEffect(() => {
    if (isSuccess && data) {
      const admin = data.find((admin) => admin.Id === id);
      setSelectedAdmin(admin);
    }
  }, [data, id, isSuccess]);

  return (
    <Flex
      height={"92.5vh"}
      flex={"1"}
      overflow={"auto"}
      bgColor={`${colors.greyLight}`}
    >
      <Box
        width={"75%"}
        fontSize={"1.25rem"}
        p={"2.5rem"}
        m={"2rem"}
        borderRadius={"5px"}
        fontFamily={"Roboto"}
        bgColor={"white"}
        height={"auto"}
        overflow={"auto"}
      >
        <Heading as={"h2"} size={"md"} fontFamily={"Roboto"} fontWeight={600}>
          Detaljer for{" "}
          {selectedAdmin ? selectedAdmin.Email.split("@")[0] : "..."}
        </Heading>
        <hr style={{ borderTop: "1px solid lightgrey" }} />

        {isLoading ? (
          <Flex
            width={"100%"}
            height={"4rem"}
            pt={"1.5rem"}
            justifyContent={"center"}
          >
            <Spinner size="lg" color={colors.greenMain} />
          </Flex>
        ) : selectedAdmin ? (
          <Box
            width={"50%"}
            border={`2px solid ${colors.blue}`}
            borderRadius={"5px"}
            my={"2.5rem"}
            p={"1rem"}
          >
            <Stack>
              <HStack>
                <Text fontWeight={"semibold"} fontSize="md">
                  Epost:
                </Text>
                <Text fontSize="md">{selectedAdmin.Email}</Text>
              </HStack>
              <HStack>
                <Text fontWeight={"semibold"} fontSize="md">
                  Rolle:
                </Text>
                <Text fontSize="md">Admin</Text>
              </HStack>
              <HStack>
                <Text fontWeight={"semibold"} fontSize="md">
                  Id:
                </Text>
                <Text fontSize="md">
                  {selectedAdmin.Id && selectedAdmin.Id.slice(0, 8)}
                </Text>
              </HStack>
              <HStack>
                <Text fontWeight={"semibold"} fontSize="md">
                  Opprettet:
                </Text>
                <Text fontSize="md">{selectedAdmin.Created}</Text>
              </HStack>
            </Stack>
          </Box>
        ) : (
          (isError || !selectedAdmin) && (
            <Text>Oops! Noe har gått galt. Venligst forsøk igjen senere.</Text>
          )
        )}
      </Box>
    </Flex>
  );
};

export default AdminDetails;
