import { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  TableContainer,
  Text,
} from "@chakra-ui/react";
import TagHouseholdElement from "./TagHouseholdElement";
import ExportToExcelHouseholdTags from "./ExportToExcelHouseholdTags";
import axios from "axios";
import { ReactComponent as Sorting } from "../../assets/icons/Sorting.svg";
const TagsHousehold = ({ householdId, tagsHousehold }) => {
  const [tags, setTags] = useState();
  const [order, setOrder] = useState("ASC");
  const fileName = "brikker/boenhet_";

  const token = localStorage.getItem("API_token");
  let headers = {
    authorization: `Bearer ${token}`,
  };

  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...tags].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setTags(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...tags].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setTags(sorted);
      setOrder("ASC");
    }
  };
  const fetchTagsHousehold = () => {
    axios
      .get(
        `https://gpshu4lon5.execute-api.eu-north-1.amazonaws.com/Test/tags/?householdId=${householdId}`,
        { headers }
      )
      .then((res) => setTags(res.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchTagsHousehold();
  }, []);
  return (
    <>
      <ControlTableButtonsContainer>
        <ExportToExcelHouseholdTags tableData={tags} fileName={fileName} />
      </ControlTableButtonsContainer>
      <TableMainContainer>
        <Table variant="simple" size="sm" colorScheme={"teal"}>
          <Thead>
            <Tr>
              <Th>
                <ThWrapper>
                  <TableHeader>Intern-id</TableHeader>
                  <SortingIcon onClick={() => sorting("InternalId")} />
                </ThWrapper>
              </Th>
              <Th>
                <ThWrapper>
                  <TableHeader>Ekstern-id</TableHeader>
                  <SortingIcon onClick={() => sorting("ExternalId")} />
                </ThWrapper>
              </Th>
              <Th>
                <ThWrapper>
                  <TableHeader>Adresse</TableHeader>
                  <SortingIcon onClick={() => sorting("Address")} />
                </ThWrapper>
              </Th>
              {/* <Th>
                  <ThWrapper>
                    <TableHeader>Eier</TableHeader>
                    <SortingIcon onClick={() => sorting("Owner")} />
                  </ThWrapper>
                </Th>
                <Th>
                  <ThWrapper>
                    <TableHeader>Opprettet</TableHeader>
                    <SortingIcon onClick={() => sorting("Created")} />
                  </ThWrapper>
                </Th> */}
            </Tr>
          </Thead>
          <Tbody>
            {tags?.map((tag, idx) => (
              <TagHouseholdElement
                key={idx}
                internalid={tag.InternalId}
                externalid={tag.ExternalId}
                address={tag.Address}
              />
            ))}
          </Tbody>
        </Table>
        {/* {!tags && (
          <SpinnerWrapper>
            <Spinner size="lg" color={colors.greenMain} />
          </SpinnerWrapper>
        )} */}
      </TableMainContainer>
    </>
  );
};
const ControlTableButtonsContainer = styled.div`
  margin-top: 2rem;
  width: 20rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const TableMainContainer = styled(TableContainer)`
  margin-top: 3rem;
`;
const ThWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const TableHeader = styled(Text)`
  font-size: 0.6rem;
  font-weight: bold;
`;
const SortingIcon = styled(Sorting)`
  width: 1rem;
  height: 1rem;
  margin-left: 0.75rem;
  cursor: pointer;
`;
const SpinnerWrapper = styled.div`
  width: 100%;
  height: 4rem;
  padding-top: 1.5rem;
  display: flex;
  justify-content: center;
`;
export default TagsHousehold;
